import React, { useState,useEffect } from 'react';
import { Service } from '../Utils/HTTP'
import { Box, Paper, Divider, Typography, Button } from '@mui/material'

import toast, { Toaster } from 'react-hot-toast';
import { useParams } from 'react-router-dom'
import { Cleaver } from './Pruebas/Cleaver';
import { Header } from '../Components/Test/Cleaver/Header';
import { useSelector, useDispatch } from 'react-redux';
import { setRole } from '../Slices/userSlice'
import { Loading } from '../Components/Generics/loading';
import { Completed } from './Pruebas/Completed';

export function Prueba() {

  let { id } = useParams();
  let auth = useSelector((state) => state.token.candi)
  let profile = useSelector((state) => state.user)
  let dispatch = useDispatch();

  let [data, setData] = useState({});
  let [asignacion, setAsignacion] = useState(null);

  useEffect(() => {
    let{ profile: perfil }=profile

    let rest = new Service(`/prueba/findid/${id}`)
    rest.get(auth.token)
      .then(resp => {
      console.log("PRUEBA:", resp.data)
        setData(resp.data)
      })
      .catch(_e => setData({}))

    let status_asign_url = `/prueba/estatus/${perfil.pass}/${perfil.email}/${id}`
    rest = new Service(status_asign_url)

    rest.get(auth.token)
      .then(resp => {
        setAsignacion(resp.data)
      })
      .catch(error => {
        console.log('error: ', error)
      })


  }, [auth.token,id,profile])

  let now = new Date().toISOString();
  let assig_data = {
    "id": -1,
    "fechaasignacio": now,
    "fechafinexamen": now,
    "estado": "1",
    "score": "0",
    "mensaje": "0",
    "mensaje2": "0",
    "candidatoinpwd_id": profile.profile.idsuperpwd,
    //candidatoinpwd -> idSuperContraseña -> viene al hacer login
    "idcontrasenia": profile.role.pass, // ya no se va enviar
    "nombre": "new " + now,
    //cattest_id // catalogo de examenes -> viene del api del catalogo del admin
    "cattest_id": data?.id
  }

  const CreateAssign = () => {
    let rest = new Service("/prueba/asignacion");
    rest.postQuery(assig_data, auth.token)
      .then(({ data: resp }) => {
        let { id: assingid } = resp

        setAsignacion({
          ...asignacion,
          estadoFinal:"ONLYASSIGNED_TEST"
        })

        toast.success("Asignación creada ", assingid)
        dispatch(setRole({ assingid, current_test_id: id }))
      })
      .catch(() => {
        toast.error("Error al crear la asignación")
      })
  }

  const DOING_STATES = ['ONLYASSIGNED_TEST', 'DOING_TEST', 'FINALY_TEST']
  // const NOT_STATE = ['NOTASSIGNED_TEST']

  console.log('ASIN: ', asignacion)
  if(!asignacion){
    return <Loading/>
  }

  if(asignacion.estadoFinal === 'FINALY_TEST'){
    return <Completed/>
  }

  if (DOING_STATES.includes(asignacion.estadoFinal)) {
    return <Cleaver />
  }

  return (
    <div className="content-section">
      <div className="main">
        <Box >
          <Paper>
            <Header />
          </Paper>
          <Paper className="prueba_body" elevation={1}>
            <h1>{data?.nombre}</h1>
            <Divider />
            <Typography style={{ marginTop: 15, textAlign: 'center' }}>{data?.decription}</Typography>
            <Divider style={{ marginTop: 15 }} />
            <Button variant="contained" onClick={CreateAssign}>Inicar Prueba</Button>
          </Paper>
        </Box>
        <Toaster
          position="top-left"
          reverseOrder={false}
        />
      </div>
    </div>
  );
}

import React from 'react'
import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';

import {
  Box, Button,
  Stack, TextField, IconButton, InputAdornment,
} from '@mui/material';

import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// import { V1, V2 } from '../../Utils/HTTP'

export function RegisterForm(props) {

  const steplen = 2;
  const index = 0;

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordTwo, setShowPasswordTwo] = useState(false);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().min(2, 'Demasiado corto!').max(50, 'Demasiado largo!').required('Tu nombre es requerido'),
    lastName: Yup.string().min(2, 'Demasiado corto!').max(50, 'Demasiado Largo!').required('El apellido es requerido'),
    email: Yup.string().email('El correo no es valido').required('Email es requerido'),
    password: Yup.string().min(5, 'La contraseña debe contener mínimo 5 caracteres').required('la contraseña es requerida'),
    password_confirm: Yup.string().required('Las contraseñas no coincidien').oneOf([Yup.ref('password'), null], 'Las contraseñas no coincidien')
  });

  let { client, setClient, handleNext, handleBack } = props

  const formik = useFormik({
    initialValues: {
      firstName: client.firstName,
      lastName: client.lastName,
      email: client.email,
      password: client.password,
      password_confirm: client.password_confirm
    },
    onSubmit: (fields) => {
      setClient({
        ...client,
        ...fields
      })
      handleNext()
    },
    validationSchema: RegisterSchema,
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider style={{ padding: 15 }} value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              label="Nombre"
              fullWidth
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              label="Apellidos"
              fullWidth
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Correo Electrónico"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Contraseña"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <TextField
            fullWidth
            type={showPasswordTwo ? 'text' : 'password'}
            label="Confirma contraseña"
            {...getFieldProps('password_confirm')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPasswordTwo((prev) => !prev)}>
                    <Icon icon={showPasswordTwo ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password_confirm && errors.password_confirm)}
            helperText={touched.password_confirm && errors.password_confirm}
          />


          <Box sx={{ mb: 2 }}>
            <div style={{ paddingTop: 15 }}>
              <Button
                name="submit_first_step"
                type="submit"
                className="registerBtn"
                variant="contained"
                sx={{ mt: 1, mr: 1 }}
              >
                {index === steplen - 1 ? 'Registrarme' : 'Siguiente'}
              </Button>
              <Button
                disabled={true}
                onClick={handleBack}
                sx={{ mt: 1, mr: 1 }}
              >
                Regresar
              </Button>
            </div>
          </Box>

        </Stack>
      </Form>
    </FormikProvider>
  );
}

import React, { memo } from 'react';
import { Modal, Row, Col } from 'react-bootstrap'

export function Eliminar(props) {

    let { visible, onClose, puesto } = props

    return(
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered  show={visible} onHide={onClose}>
            <Modal.Header>
                <button onClick={onClose} type="button" className="close" data-dismiss="modal">&times;</button>
                <h4 className="modal-title">¡Atención!</h4>
            </Modal.Header>
            <Modal.Body className="modal-body">

                <h1 className="alert">¿Estás seguro que deseas eliminar esta plaza?</h1>
                <p className="text-alert">Una vez eliminado no será posible recuperarla</p>
                <Row>
                    <Col md="6" sm="6" xs="6">
                        <div className="cancel_producto_confirm">
                            <div className="btn_cancelar">
                                <a href="/" type="submit">Cancelar</a>
                            </div>
                        </div>
                    </Col>
                    <Col md="6" sm="6" xs="6">
                        <div className="delet_producto_confirm">
                            <div className="btn_delete_producto_confirm">
                                <a  href="/" onClick={() => console.log('ID >> ',puesto.id)} type="submit">Eliminar</a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}


export default memo(Eliminar);

import Logo from '../Images/logo.png'

export function RestorePassword() {
    return(
        <section className="error_page">
            <div className="page_notfound">
                <div className="row">
                    <div className="col-md-12">    
                        <div className="contenedor_restore">            
                            <div className="logo">
                                <img alt='forgot password logo' src={Logo}/>
                            </div>
                            <div className="form_restore">
                                <h1>¿Olvidaste tu contraseña?</h1>
                                <p>Ingresa tu correo electrónico a continuación y te estaremos enviando instrucciones para cambiar tu contraseña.</p>
                                <form>
                                    <input type="email" name="mail" placeholder="Ingresa tu correo electrónico" required/>
                                    <button className="restore_btn" type="submit">Enviar</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        </section>
    )
}

import { Col, Row }  from 'react-bootstrap'

export function NotFound() {
    return(
        <section className="error_page">
            <div className="page_notfound">
                <Row className="row">
                    <Col md="12">
                        <h1>404</h1>
                        <h3>Upss!.. Algo salió mal</h3>
                        <p>No pudimos encontrar la página que estás buscando.</p>
                    </Col>
                </Row>
            </div>    
        </section>
    )
}

import * as React from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import jwt_decode from "jwt-decode";
import { useDispatch , useSelector } from "react-redux";

import { setToken } from '../Slices/tokenSlice'
import { setProfile, setRole } from '../Slices/userSlice.js'

import {
  Paper, Box, Grid, Typography, TextField, Button, Avatar,
  Backdrop, CircularProgress,
} from '@mui/material'

import { createTheme, ThemeProvider } from '@mui/material/styles';

import PersonIcon from '@mui/icons-material/Person';
import { Copyright } from '../Components/Footer.js'

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Service } from '../Utils/HTTP.js'

const LoginSchema = Yup.object().shape({
  email: Yup
    .string()
    .email('El correo debe ser válido')
    .required('El correo o usuario es requerido'),
  password: Yup
    .string()
    .required('El campo contraseña es requerido')
    .min(5, 'La contraseña debe contener mínimo 5 caracteres')
})

const theme = createTheme();

export function LoginCs() {

  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const handleClose = () => false
  const navigate = useNavigate();
  const { usuario, pwd } = useParams();
  // console.log('usuariopwd: ',{usuario,pwd})
  const auth = useSelector((state) => state.token.candi)

  React.useEffect(() => {
    if(auth){
      const body_token = jwt_decode(auth.token);
      if(body_token.authorities.includes("Candidato")){
        navigate('/user/home')
      }
    }
  }, [auth, navigate])

  const formik = useFormik({
    initialValues: {
      email: usuario ?  usuario : '',
      password: pwd ? pwd:  '',
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {

      const { email, password } = values
      setOpen(true)

      const url = `/user?user=${email}&password=${password}&typeusr=iscandidato`
      const request = new Service(url);
      request
        .post({})
        .then(response => {

          console.log("Service Response :: ", response)
          let { token, nombre, apelidos, idcontrasenia : pass_id, idSueperContrasenia } = response;
          toast.success(`Bienvenido ${nombre} ${apelidos}!!`)
          token = token.replace("Bearer ", "")
          const { exp } = jwt_decode(token);
          const body_token = jwt_decode(token);
          console.log('body_toke: ', body_token)
          body_token['pass'] = password;
          body_token['passid'] = pass_id;

          const timestamp = exp * 1000;
          let exp_in = timestamp - Date.now();
          let profile = {
            email,
            password,
            passid: pass_id,
            pass: password,
            assingid: 0,
            idsuperpwd: idSueperContrasenia
          }

          setTimeout(() => alert("Token Expirado"), exp_in)
          setTimeout(() => {
            setOpen(false)
            dispatch(setRole(body_token))
            dispatch(setProfile(profile))
            dispatch(setToken({ token : token, exp : exp_in, candi : true }))
          }, 2000)

        })
        .catch(err => {
          setOpen(false)
          toast.error("Ups! usuario o contraseña incorrectos")
          console.log("ERROR ", err)
        })

    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <Grid item xs={12} sm={12} md={12} component={Paper} elevation={6} square>

          <Box
            sx={{
              my: 8,
              mx: 4,
              marginTop: 25,
              display: 'flex',
              flexDirection: 'column',
              // alignItems:true?'flex-start': 'center',
              alignItems: 'center'
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'var(--second)' }}>
              <PersonIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Ingresar
            </Typography>

            <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
              <TextField
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                margin="normal"
                required
                fullWidth
                id="email"
                name="email"
                label="Correo Electrónico"
                autoComplete="email"
                autoFocus
              />
              <TextField
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                margin="normal"
                required
                fullWidth
                label="Contraseña"
                name="password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                id="login_btn_fn"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, bgcolor: 'var(--second)' }}
              >
                Ingresar
              </Button>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Toaster
        position="top-left"
        reverseOrder={false}
      />
      <Backdrop
        sx={{ color: '#fd4b4b', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

    </ThemeProvider>

  );
}

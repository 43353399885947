import React, { memo } from 'react';
import { Modal, Col, Row ,Image } from 'react-bootstrap'
// import QA from '../../Images/puesto.jpg'
import QA from '../../Images/register_mok.png'

import { Divider, Chip } from '@mui/material'

function Mostrar(props) {

    let { visible, puesto } = props
    const opciones = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
    const close = () => props.toggle("VER");


    return (
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={visible} onHide={close}>
            <Modal.Header>
                <button onClick={close} type="button" className="close" data-dismiss="modal">&times;</button>
                <h4 className="modal-title">Mostrar plaza</h4>
            </Modal.Header>
            <Modal.Body className="modal-body">

                <div className="data_product">
                    <Row >
                        <Col md="4">
                            <div >
                              <Image style={{width: '100%'}} fuild={true}  alt='not found cooo' src={QA} />
                            </div>
                        </Col>
                        <Col md="8">
                            <input value={puesto ? puesto.nombrepuesto : "..."} type="text" name="nombre" placeholder="..." readOnly />
                            <input value={puesto ? puesto.notas : '...'} type="text" name="descript" placeholder="..." readOnly />
                            <input
                                value={new Date(puesto ? puesto.fecha : new Date()).toLocaleDateString('es-GT', opciones)}
                                type="text"
                                placeholder="...."
                                name="sku" readOnly />
                            <div>
                                {
                                    puesto && visible
                                    ? puesto.tests.map( ( {decription, id} ) => 
                                      (<Chip style={{background:'var(--main)', color: 'white', fontWeight: "bold"}} key={id} label={decription}/>)
                                    ) : null
                                }
                            </div>
                            <Divider/>
                        </Col>
                    </Row>
                </div>

            </Modal.Body>
        </Modal>
    )
}

export default memo(Mostrar);

import React from 'react'
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';

import {
  Box, Button,
  Stack, TextField,
} from '@mui/material';

import { AdapterDateFns as DateFnsUtils } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

export function Password(props) {

  const [uid, setUID] = React.useState(null);

  const PasswordSchema = Yup.object().shape({
    pwd:
      Yup
        .string()
        .required('Ingresa un identificador válido')
        .min(5, "Ingresa un identificador válido")
        .max(50, "identificador demasiado largo"),
    deadpwd: Yup.date("Ingresa una fecha válida"),
    dateToActived: Yup.date("Ingresa una fecha válida"),
  });

  let { handleNext, handleBack, password, setPassword } = props
  // console.log('PASS: ',password)

  const formik = useFormik({
    initialValues: {
      pwd: password.pwd,
      deadpwd: password.deadpwd,
      dateToActived: password.dateToActived,
    },
    onSubmit: (fields) => {
      fields['deadpwd'] = new Date(fields.deadpwd).toISOString();
      fields['dateToActived'] = new Date(fields.dateToActived).toISOString();
      setPassword({
        ...password,
        ...fields
      })
      handleNext()
    },
    validationSchema: PasswordSchema,
  });

  const { errors, touched, handleSubmit, getFieldProps, values, setValues } = formik;

  return (
    <FormikProvider style={{ padding: 25, paddingTop: 5 }} value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack spacing={2}>
            <TextField
              fullWidth
              type="text"
              label="Nombre o identificador"jdlkfajdfajlakjdsfla
              {...getFieldProps('pwd')}
              onChange={(event) => {
                let value = event.target.value
                let last = value.substring(value.length - 1, value.length)
                let pattern = /^[a-zA-Z ]*$/
                let is_letter =  pattern.test(last)
                if(!is_letter) return
                setUID(btoa(value));
                setValues({
                  ...values,
                  pwd: value
                })
              }}
              error={Boolean(touched.pwd && errors.pwd)}
              helperText={touched.pwd && errors.pwd}
            />

            <TextField
              value={uid ? uid : btoa(values.pwd)}
              disabled
              fullWidth
              type="text"
              label="Identificador Codificado"
              variant="standard"
            />
          </Stack>


          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

            <LocalizationProvider dateAdapter={DateFnsUtils}>

              <DesktopDatePicker
                label="Fecha de Activación"
                fullWidth
                inputFormat="dd/MM/yyyy"
                {...getFieldProps('dateToActived')}
                value={values.dateToActived}
                onChange={(val) => setValues({ ...values, dateToActived: new Date(val) })}
                renderInput={(params) =>
                  <TextField
                    error={Boolean(touched.dateToActived && errors.dateToActived)}
                    helperText={touched.dateToActived && errors.dateToActived}
                    disabled={true}
                    label="Fecha de Activación"
                    fullWidth
                    {...params}
                  />}
              />

              <DesktopDatePicker
                label="Fecha de Caducidad"
                fullWidth
                inputFormat="dd/MM/yyyy"
                {...getFieldProps('deadpwd')}
                value={values.deadpwd}
                onChange={(val) => setValues({ ...values, deadpwd: new Date(val) })}
                renderInput={(params) =>
                  <TextField
                    error={Boolean(touched.deadpwd && errors.deadpwd)}
                    helperText={touched.deadpwd && errors.deadpwd}
                    disabled={true}
                    label="Fecha de Caducidad"
                    fullWidth
                    {...params}
                  />}
              />

            </LocalizationProvider>


          </Stack>

          <Box sx={{ mb: 2 }}>
            <div style={{ paddingTop: 15 }}>
              <Button
                type="submit"
                className="registerBtn"
                variant="contained"
                sx={{ mt: 1, mr: 1 }}
              >
                {'Siguiente'}
              </Button>
              <Button
                disabled={false}
                onClick={handleBack}
                sx={{ mt: 1, mr: 1 }}
              >
                Regresar
              </Button>
            </div>
          </Box>

        </Stack>
      </Form>
    </FormikProvider>
  );
}

import * as React from 'react';

import { Table } from 'react-bootstrap';
import {
  Box, Button, LinearProgress,
  Backdrop, CircularProgress
} from '@mui/material';

import toast, { Toaster } from 'react-hot-toast';

import { useMutation, useQueryClient } from 'react-query';
import { Service } from '../../../Utils/HTTP.js'
import { useSelector } from 'react-redux';

import { createTheme, ThemeProvider } from '@mui/material/styles';

let theme = createTheme({
  status: {
    success: '#fd4b4b'
  },
  palette: {
    primary: {
      main: '#fd4b4b',
    },
    secondary: {
      main: '#fd4b4b',
    },
  },
});

export function Resume(props) {

  let { handleBack, password: key, handleClose, handleReset } = props

  const fmt = React.useRef({ weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' })
  const [loading, setLoading] = React.useState(false);

  const auth = useSelector((state) => state.token)
  const profile = useSelector((state) => state.user.profile)
  const queryClient = useQueryClient();

  const savePassword = async (body) => {
    let rest = new Service('/contrasenia/create')
    return await rest.postQuery(body, auth.token)
  }

  const saveCandidato = async (body) => {
    let rest = new Service('/passwordcandidato/candidato')
    return await rest.postQuery(body, auth.token)
  }

  const pwdMutation = useMutation('password', savePassword);
  const candiMutation = useMutation('candidato', saveCandidato);

  const saveStepper = () => {

    setLoading(true);

    let { deadpwd, dateToActived } = key;

    let pwdBody = {
      id: -1,
      pwd: btoa(key.pwd),
      link: "www.psicoadmin.com",
      deadpwd: new Date(deadpwd).toISOString(),
      state: 1,
      dateToActived: new Date(dateToActived).toISOString(),
      plaza_id: key.puesto[0].id
    }

    pwdMutation.mutate(pwdBody, {
      onSuccess: (result) => {

        let { id: idContrasenia } = result.data;

        console.log("contrasenia creada : ", idContrasenia)

        let candidatos_body = key.candidatos.map((candi) => ({
          "id": -1,
          "nombres": candi.nombres,
          "apellidos": candi.apellidos,
          "mail": candi.mail,
          "sendmail": 1,
          "idContrasenia": idContrasenia,
          "nombrepuesto": 'Senior Software',
          "nombreEmpresa": 'Google'
        }))

        candiMutation.mutate(candidatos_body, {
          onSuccess: (data) => {
            queryClient.invalidateQueries('passwords')
            toast.success("Contraseña agregada exitosamente!!")
            setTimeout(() => {
              console.log("envio  a candidatos >> ", data)
              setLoading(false);
              handleClose();
              handleReset();
            }, 1000)
          },
          onError: () => {
            toast.error("Ups!! error al crear la contraseña")
            setLoading(false);
          }
        })

      },
      onError: () => {
        console.log("No se pudo guardar pwd")
        setLoading(false);
        toast.error("Ups!! Ocurrio un error, inténtalo más tarde")
      }
    })
  }

  const getLi = (user, i) => {
    return (
      <li key={i} style={{ listStyleType: 'none' }}>
        {user.nombres + " " + user.apellidos} - {user.mail}
      </li>
    )
  }

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        {loading ? (
          <Box sx={{ paddingBottom: 3 }}>
            <LinearProgress color="inherit" />
          </Box>
        ) : null}
        <Table>
          <thead>
            <tr className="table_password">
              <th>{"Contraseña: "}</th>
              <th>{key.pwd} - {btoa(key.pwd)}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="title_td">{"Candidatos:"}</td>
              <td colSpan={2}>
                <ul style={{ margin: 0, padding: 0 }}>
                  {key.candidatos ? key.candidatos.map((u, i) => getLi(u, i)) : null}
                </ul>
              </td>
            </tr>
            <tr>
              <td className="title_td">{"Puesto:"}</td>
              <td colSpan={2}>{key.puesto.length > 0 ? key.puesto[0].nombrepuesto : ''}</td>
            </tr>
            <tr>
              <td className="title_td">{"Empresa:"}</td>
              <td colSpan={2}>{profile ? profile.nombrecpmercial : ""}</td>
            </tr>
            <tr>
              <td className="title_td">{"Fecha Activación:"}</td>
              <td colSpan={2}>{new Date(key.dateToActived).toLocaleDateString('es-GT', fmt.current)}</td>
            </tr>
            <tr>
              <td className="title_td">{"Fecha de Vencimiento:"}</td>
              <td colSpan={2}>{new Date(key.deadpwd).toLocaleDateString('es-GT', fmt.current)}</td>
            </tr>
          </tbody>
        </Table>


        <Box sx={{ mb: 2 }}>
          <div style={{ paddingTop: 15 }}>
            <Button
              disabled={loading}
              style={{
                color: loading ? 'white' : ''
              }}
              onClick={saveStepper}
              className="registerBtn"
              variant="contained"
              sx={{ mt: 1, mr: 1 }}
            >
              {'Guardar'}
            </Button>
            <Button
              disabled={loading}
              onClick={handleBack}
              sx={{ mt: 1, mr: 1 }}
            >
              Regresar
            </Button>
          </div>
        </Box>
      </ThemeProvider>
      <Backdrop
        sx={{ color: '#fd4b4b', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={() => console.log("close fetching")} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Toaster position="bottom-right" />
    </React.Fragment>
  )
}

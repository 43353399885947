import React from 'react'
import {
  Skeleton, Box, IconButton, TableCell, TableContainer,
  Table, TableRow, Paper, TableBody,
  Tooltip, Button
} from '@mui/material';
import {
  CheckBox as CheckBoxIcon,
  AddTask as AddTaskIcon,
  NewReleases as NewReleasesIcon,
  Error as ErrorIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon
} from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { Service } from '../../Utils/HTTP';
const big_as = 70


function IconStatus(props) {

  let navigate = useNavigate()
  let auth = useSelector((state) => state.token)

  const calificar = () => {
    let rest = new Service(`/prueba/calificacion/cleaver/report/${props.pwd}`);

    rest
      .getQuery(auth.token)
      .then(r => {
        navigate('/dashboard/resultados/' + props.pwd)
      })
      .catch(e => {
        // navigate('/dashboard/resultados/' + props.pwd)
      })
  }


  return (
    <Tooltip title={props.message}>
      <IconButton
        onClick={() => {
          if (parseInt(props.estado) === 100) {
            calificar()
            // navigate('/dashboard/resultados/' + props.pwd)
          }
        }}
        style={{ color: props.color }}
        components="label">
        {<props.icon />}
      </IconButton>
    </Tooltip>

  )
}

function getOperation(estado, SuperPWD) {
  // Patrik: 100 -> Significa que el examen esta completo
  // Patrik: 99 -> Que el candidato lo esta haciendo (como "doing test" del otro servicio)
  // Patrik: 97 ->  Que hay un error y que hay mas respuestas de las que deberian
  // Patrik: 1 -> Solo esta asignado.
  switch (estado) {
    case 1: // solo asignado
      return <IconStatus color="#0bd641" icon={AddTaskIcon} message="Candidato Asignado" />
    case 97: //error en las respuestas
      return <IconStatus color="var(--main)"  icon={ErrorIcon} message="Hay error en las respuestas" />
    case 99: // el candidato se encuentra realizndolo
      return <IconStatus color="#f5f511" icon={AssignmentTurnedInIcon} message="El candidato se encuentra realizando la prueba" />
    case 100: // finalizado
      return <IconStatus color="#0bd641" estado={100} icon={CheckBoxIcon} message="Calificar el examen" pwd={SuperPWD} />
    default: return null
  }
}

function Asignaciones(props) {

  let { estado, cattest, id: SuperPWD } = props.asign;

  return (
    <tr>
      <td className="asign_status">
        {cattest.decription}
        {/*
          test_result.length > 0 ?
            (
              <Tooltip title="Mostrar resultados">
                <IconButton
                  onClick={() => navigate('/dashboard/resultados/' + SuperPWD)}
                  style={{ color: '#0bd641' }}
                  aria-label="puesto_status"
                  components="label">
                  <CheckBoxIcon />
                </IconButton>
              </Tooltip>
            ) : null
      */}
        {
          getOperation(estado, SuperPWD)
        }
      </td>
    </tr>
  )
}

function TableUser(props) {
  let { user } = props;
  let { asignaciones, candi } = user

  return (
    <TableRow className="bbottom">
      <TableCell
        style={{ borderRight: '1px solid #f1f1f1 !important' }}
        className="home_letter pwd_user_name pwd_info">
        {candi.nombre + " " + candi.apellidos}
      </TableCell>
      <TableCell className="table_list_test_user home_letter pwd_info">
        <table>
          <tbody >
            {
              asignaciones?.length > 0 ?
                asignaciones.map(a => <Asignaciones key={a.id} asign={a} />)
                : <SinAsignaciones />
            }
          </tbody>
        </table>
      </TableCell>
    </TableRow>
  )
}

function SinAsignaciones() {
  return (
    <tr style={{ padding: 5 }}>
      <td style={{ textAlign: 'center' }} className="home_letter">
        <i> El candidato aun no cuenta con asignaciones... </i>
        {/* <FmdBadIcon style={{ color: 'var(--main)' }} /> */}
      </td>
    </tr>
  )
}

export function Candidato(props) {

  let { password, candidatos } = props.user

  return (
    <div className="data_candidato">
      <Row>

        <Col className="pwd_info pwd_name_candi" onClick={props.onClick} md="3" sm="12">
          <Button className="btn_pwd" variant="contained">
            <u><p><b>{password?.toUpperCase()}</b></p></u>
          </Button>
        </Col>

        <Col className="pwd_info" md="9">
          <TableContainer style={{ boxShadow: "none" }} component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                {
                  candidatos.length > 0 ?
                    candidatos.map(u => <TableUser key={u.candi.id} user={u} />)
                    : <SinAsignRealizadas />
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Col>
      </Row>
    </div>
  )
}


function SinAsignRealizadas() {
  return (
    <tr style={{ padding: 5 }}>
      <td style={{ textAlign: 'center' }} className="home_letter">
        <i> No se ha relizado ninguna asignacion... </i>
        <NewReleasesIcon style={{ color: 'var(--main)' }} />
      </td>
    </tr>
  )
}

export function Preview() {
  console.log('preview')
  return (
    <Box sx={{ width: '100%' }}>
      <Skeleton height={big_as} animation="wave" />
      <Skeleton height={big_as} animation="wave" />
      <Skeleton height={big_as} animation="wave" />
      <Skeleton height={big_as} animation="wave" />
      <Skeleton height={big_as} animation="wave" />
    </Box>
  )
}

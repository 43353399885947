import React, { memo, useEffect, useCallback, useRef } from 'react';
import { Modal } from 'react-bootstrap'
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';


import { TabPanel } from './TabPanel'
import { AdapterDateFns as DateFnsUtils } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import {
  Button, Stack, TextField, MenuItem, FormControl, InputLabel, Select,
  Backdrop, CircularProgress,
  Tabs, Tab, Box, Divider, FormGroup, FormControlLabel, Checkbox,
  Dialog, DialogContent, DialogTitle, DialogActions,
  DialogContentText,
} from '@mui/material';

import toast, { Toaster } from 'react-hot-toast';

import { Service } from '../../Utils/HTTP';
import { useSelector } from 'react-redux'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
const filter = createFilterOptions();

async function getPuestoSuperior(puesto, auth) {
  // console.log('P:', puesto)
  if (puesto.length < 2) return []
  let rest = new Service(`/plaza/keypuestosup?keyword=${puesto}`)
  let result = await rest.get(auth.token)
  if (result?.data?.length > 0) {
    result = result.data.map((item) => ({ 'title': item.nombre, id: item.id }))
    return result;
  }
  return [];
}

async function savePuestoSuperior(input, auth) {
  let rest = new Service("/plaza/puestosuperior")
  let body = {
    "active": 1,
    "nombre": input.title,
    "decription": input.id,
    "modifyday": "2023-02-12T23:55:26.007",
    "createday": "2023-02-12T23:55:26.007",
    "id": null,
    "descripction": input.id,
    "modify_day": "2023-02-12T23:55:26.007",
  }
  let result = await rest.post(body, auth.token);
  let { id, nombre } = result;
  return { id, nombre }
}

const plazeSchema = Yup.object({
  id: Yup.number(),
  nombrepuesto:
    Yup.string().required('El nombre es requerido')
      .min(5, "El nombre del  puesto debe ser mayor a 5 caracteres")
      .max(100),
  puestosuperior: Yup.number("El puesto superior debe ser un número").required("El puesto es requerido"),
  aredepto: Yup.number('El área debe ser un número').required('Escoge alguna área'),
  fecha: Yup.date("Ingresa una fecha válida").required(),
  notas: Yup.string("Ingresa una nota válida").min(5, "Ingresa una nota mayor a 5").max(150, "Ingresa una nota menor a 150 caracteres"),
  tests: Yup.array()
}).required();


function Edit(props) {

  const [date, setDate] = React.useState(new Date());

  const formik = useFormik({
    initialValues: {
      id: 0,
      nombrepuesto: "",
      puestosuperior: 0,
      aredepto: 1,
      fecha: date,
      notas: "",
      tests: []
    },
    onSubmit: (fields, { resetForm }) => {
      console.log('submit values', fields)
      saveEditPlaza(fields)
    },
    validationSchema: plazeSchema,
  });


  const { errors, touched, handleSubmit, getFieldProps, values, setValues } = formik;

  const saveEditPlaza = data => {

    let body = {
      ...data,
      fecha: new Date(data.fecha).toISOString(),
    }

    puestoMutation.mutate(body, {
      onSuccess: () => {
        close();
        setOpen(false)
        toast.success("Puesto Actualizado!!")
        queryClient.invalidateQueries('puestos')
      },
      onError: () => {
        toast.error("Lo sentimos ocurrió error inténtalo más tarde")
      }
    })

  }

  const auth = useSelector((state) => state.token)
  const queryClient = useQueryClient()
  let { visible, toggle } = props

  const [open, setOpen] = React.useState(false);
  const [departamento, setDepartamento] = React.useState('');
  const puestoref = useRef(null);
  const [tab, setTab] = React.useState(0);
  const [checklist, setChecklist] = React.useState([]);
  const [openSugg, setOpenSugg] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [openDialog, toggleOpenDialog] = React.useState(false);
  const [dialogValue, setDialogValueHook] = React.useState({
    title: '',
    id: '',
  });


  const handleCloseDialog = () => {
    toggleOpenDialog(false);
  };

  const handleSubmitDialog = async (event) => {
    // console.log('handlesubmit', dialogValue)
    event.preventDefault();
    let { id, nombre } = await savePuestoSuperior(dialogValue, auth)
    if (id) {
      let to_set = {
        title: nombre,
        id: id,
      }
      if (to_set.id) {
        // console.log("TO SET", to_set)
        setDialogValue(to_set);
        setValues({...values, puestosuperior: to_set.id})
      }
    }
    handleCloseDialog();
  };

  let setDialogValue = useCallback((value) => {
    // console.log('value receivied', value)
    let id = value?.id || 0;
    if (id !== 0) {
      console.log('setting value', id)

    }
    setDialogValueHook(value)
  }, [])

  const changeDepartamento = (event) => {
    setDepartamento(event.target.value);
  };

  const loading = openSugg && options.length === 0;

  const AutoCompleteChange = (event, newValue) => {
    setValues({ ...values, puestosuperior: newValue?.id })

    if (typeof newValue === 'string') {
      // console.log('if1')
      setTimeout(() => {
        toggleOpenDialog(true);
        setDialogValue({
          title: newValue,
          id: '',
        });
      });
    } else if (newValue && newValue.inputValue) {
      // console.log('if2')
      toggleOpenDialog(true);
      setDialogValue({
        title: newValue.inputValue,
        id: '',
      });
    } else {
      // console.log('if3')
      setDialogValue(newValue);
    }
  }

  const addPrueba = (check, id) => {
    let { tests } = values
    let temp;
    if (check) {
      temp = [...tests, { id }]
    } else {
      temp = tests.filter(test => test.id !== id);
    }
    setChecklist(temp.map(test => test.id))
    // setValue('tests', temp)
    setValues({ ...values, tests: temp })
  };

  const getCategories = async () => {
    let rest = new Service("/categoria/getAll")
    return await rest.getQuery(auth.token)
  }

  const updatePuesto = async (fields) => {
    let rest = new Service('/plaza/edit');
    return rest.putQuery(fields, auth.token)
  }

  const getTest = async () => {
    let rest = new Service("/tests/getAll")
    return await rest.getQuery(auth.token)
  }

  const puestoMutation = useMutation(updatePuesto)
  const close = () => toggle("EDIT", { id: null });

  const { data: categories } = useQuery('categories', getCategories);
  const { data: testsCatalog } = useQuery('tests', getTest);

  useEffect(() => {

    let current_id = values.id; // getValues('id')

    if (visible == null) return;

    if (current_id !== visible) {
      let rest = new Service(`/plaza/getthis/${visible}`)
      rest
        .getQuery(auth.token)
        .then(response => {
        console.log('plz to edit: ', response.data)
          let { areadeptoplz_id, fecha, tests, puestosuperior } = response.data;
          let temp_test = tests.map(t => ({ id: t.id }))
          setChecklist(temp_test.map(t => t.id))
          // console.log('puesto sup', puestosuperior)
          setDialogValue({
            title: puestosuperior.nombre,
            id: puestosuperior.id
          })
          setValues({
            ...response.data,
            aredepto: areadeptoplz_id,
            fecha: new Date(fecha),
            tests: temp_test,
            puestosuperior: puestosuperior.id
          })
          // console.log("INITIAL:", getValues())
        })
        .catch(e => console.log(e))

    }

    let active = true;
    if (!loading) {
      return undefined;
    }

    (async () => {
      let puestos = await getPuestoSuperior(dialogValue.title, auth)
      if (active) {
        setOptions(puestos);
      }
    })();

    return () => {
      active = false;
    };

  }, [visible, auth, loading, dialogValue, setDialogValue,setValues,values.id])

  const changeTab = (_event, newValue) => setTab(newValue);

  return (

    <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={visible} onHide={close}>
      <Modal.Header>
        <button onClick={close} type="button" className="close" data-dismiss="modal">&times;</button>
        <h4 className="modal-title" style={{ color: '#252525' }}>Editar puesto</h4>
      </Modal.Header>
      <Modal.Body className="modal-body">

        <Tabs value={tab} onChange={changeTab} aria-label="basic tabs example">
          <Tab label="Información" />
          <Tab label="Pruebas" />
        </Tabs>

        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <form onSubmit={handleSubmitDialog}>
            <DialogTitle>Agrega un nuevo Puesto</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Agrega la descripcion del puesto
              </DialogContentText>

              <TextField
                autoFocus
                margin="dense"
                id="name"
                value={dialogValue?.title}
                onChange={(event) => {
                  // console.log('aa', event.target.value)
                  setDialogValue({
                    ...dialogValue,
                    title: event.target.value,
                  })
                }
                }
                label="Puesto"
                type="text"
                variant="standard"
              />

              <TextField
                margin="dense"
                id="name"
                value={dialogValue?.id}
                onChange={(event) => {
                  // console.log('ev', event.target.value)
                  setDialogValue({
                    ...dialogValue,
                    id: event.target.value,
                  })
                }
                }
                label="Descripción"
                type="text"
                variant="standard"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}>Cancelar</Button>
              <Button type="submit">Agregar</Button>
            </DialogActions>
          </form>
        </Dialog>

        <FormikProvider style={{ paddingTop: 25 }} value={formik}>
          <Form onSubmit={handleSubmit}>
            <TabPanel value={tab} index={1}>
              <Stack spacing={1}>
                <Box style={{ paddingTop: 5, paddingLeft: 15 }}>
                  <Divider />
                  <h4 style={{ paddingTop: 10, paddingBottom: 10 }}>Seleciona los test a realizar</h4>
                  <Divider />
                  <FormGroup>
                    {
                      testsCatalog ?
                        testsCatalog.data.map(test => (
                          <FormControlLabel
                            label={test.nombre}
                            key={test.id}
                            control={
                              <Checkbox
                                checked={checklist.includes((test.id))}
                                onChange={(event) => addPrueba(event.target.checked, test.id)} />}
                          />
                        )) : null
                    }
                  </FormGroup>
                </Box>
              </Stack>
            </TabPanel>

            <TabPanel value={tab} index={0} >
              <Stack spacing={3}>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

                  <TextField
                    name="nombrepuesto"
                    label="Nombre"
                    fullWidth
                    helperText={errors.nombrepuesto?.message}
                    error={Boolean(errors?.nombrepuesto)}
                    {...getFieldProps("nombrepuesto")} />


                  <FormControl fullWidth>
                    <Autocomplete
                      fullWidth
                      value={dialogValue}
                      onChange={AutoCompleteChange}
                      open={openSugg}
                      onOpen={() => {
                        setOpenSugg(true);
                      }}
                      onClose={() => {
                        setOpenSugg(false);
                      }}
                      isOptionEqualToValue={(option, value) => option.title === value.title}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        if (params.inputValue !== '') {
                          filtered.push({
                            inputValue: params.inputValue,
                            title: `Agregar "${params.inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      id="puesto_superior_autocomplete"
                      options={options}
                      loading={loading}
                      getOptionLabel={(option) => {
                        if (typeof option === 'string') {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option.title;
                      }}
                      selectOnFocus
                      // clearOnBlur
                      // handleHomeEndKeys
                      renderOption={(props, option) => <li key={option.id} {...props}>{option.title}</li>}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          {...getFieldProps('puestosuperior')}
                          error={Boolean(errors.puestosuperior)}
                          label="Puesto Superior"
                          InputProps={{
                            ...params.InputProps,
                            onChange: (event) => {
                              let title = event.target.value;
                              // console.log('titulo', title)
                              setOptions([]);
                              setDialogValue({
                                title: title,
                                id: '',
                              });
                            },
                            endAdornment: (
                              <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}

                    />
                  </FormControl>

                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label2">Departamento</InputLabel>
                    <Select
                      value={departamento}
                      label="Departamento"
                      onChange={changeDepartamento}
                      {...getFieldProps('aredepto')}
                      error={Boolean(touched.aredepto && errors.aredepto)}>
                      {
                        categories ?
                          categories.data.map(cate => {
                            return (
                              <MenuItem key={cate.id} value={cate.id}>{cate.nombre}</MenuItem>
                            )
                          })
                          : <MenuItem value={1}>hola</MenuItem>
                      }
                    </Select>
                  </FormControl>

                  <LocalizationProvider dateAdapter={DateFnsUtils}>
                    <DesktopDatePicker
                      {...getFieldProps('fecha')}
                      helperText={errors.fecha?.message}
                      error={Boolean(errors?.fecha)}
                      label="Fecha Creación"
                      fullWidth
                      value={date}
                      onChange={setDate}
                      inputFormat="dd/MM/yyyy"
                      renderInput={ (params) => 
                        <TextField 
                        disabled={true}
                        {...params} 
                        helperText={errors.fecha?.message} 
                        />
                      }
                    />
                  </LocalizationProvider>
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                  <TextField
                    helperText={errors.notas?.message}
                    error={Boolean(errors?.notas)}
                    name="notas"
                    multiline
                    rows={4}
                    label="Notas"
                    fullWidth
                    type="text"
                    {...getFieldProps("notas")}
                  />
                </Stack>
              </Stack>
            </TabPanel>

            <Modal.Footer>
              <Button
                ref={puestoref}
                type="submit"
                className="registerBtn"
                variant="contained"
                sx={{ mt: 1, mr: 1 }} >
                {'Actualizar'}
              </Button>
            </Modal.Footer>

          </Form>
        </FormikProvider>

      </Modal.Body>
      <Backdrop
        sx={{ color: '#fd4b4b', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={() => console.log('backdrop')} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Toaster position="top-right" />

    </Modal>
  )
}

export default memo(Edit);

import { createSlice } from '@reduxjs/toolkit';
const initialState = { token: null, candi: null };

export const tokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {
    setToken: (state, action) => {
      let { payload } = action
      if (payload.candi) {
        state.candi = action.payload
      } else {
        state.token = action.payload;
      }
    },
    removeToken: (state, action) => {
      let { payload } = action
      if (payload?.candi) {
        state.candi = null
      }
      state.token = null;
    }
  }
})

export const { setToken, removeToken } = tokenSlice.actions;
export default tokenSlice.reducer;

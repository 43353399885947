import { createTheme } from '@mui/material';


// MuiTableCell-root MuiTableCell-body MuiTableCell-paddingCheckbox MuiTableCell-sizeMedium tss-12o2szd-MUIDataTableSelectCell-root tss-1fz4yw6-MUIDataTableSelectCell-fixedLeft css-1nf681w-MuiTableCell-root

export const TableStyle = () => createTheme({
  components: {

    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: 'var(--main)'
        }
      }
    },

    MUIDataTableSelectCell: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFF'
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: 'var(--main)'
        }
      }
    },
    MuiChecked: {
      styleOverrides: {
        root: {
          color: 'red'
        }
      }
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        root: {
          paddingLeft: 15
        }
      }
    },
    MuiRowSelected: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFF"
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none'
        }
      }

    },
    MUIDataTableSelectedCell: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFF"
        }
      }
    },
    MUITableCell: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFF"
        }
      }
    },
    MUIDataTableBodyRow: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFF"
        }
      }
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFF"
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          paddingTop: 7,
          alignItems: 'baseline'
        }
      }
    }
  }
})

export const TextLabels = {
  body: {
    noMatch: "No se encontró ningún elemento",
    toolTip: "Ordenar",
    columnHeaderTooltip: column => `Ordenar por ${column.label}`
  },
  pagination: {
    next: "Siguiente Pagina",
    previous: "Pagina Anterior",
    rowsPerPage: "Elementos por Página",
    displayRows: "de",
  },
  toolbar: {
    search: "Buscar",
    downloadCsv: "Descargar CSV",
    print: "Imprimir",
    viewColumns: "Seleccionar Columnas",
    filterTable: "Filtrar Tabla",
  },
  filter: {
    all: "Todos",
    title: "FILTROS",
    reset: "Limpiar",
  },
  viewColumns: {
    title: "Mostrar Columnas",
    titleAria: "Mostrar/Ocultar Columnas",
  },
  selectedRows: {
    text: "Elemento(s) selecionado",
    delete: "Eliminar",
    deleteAria: "Eliminar Columnas Seleccionadas",
  },
}

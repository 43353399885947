import * as React from 'react';

import { MainListItems, SecondaryListItems} from './listItems'
import { List, Divider } from '@mui/material';
import { Logout } from '@mui/icons-material/';
import { Nav } from 'react-bootstrap';
import { 
    ListItem, ListItemIcon,ListItemText,
    SwipeableDrawer
} from '@mui/material/'

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { removeToken } from '../../Slices/tokenSlice';

const WITH_OF_PANEL = 70;

export function MenuMovil(props) {
  let { anchor, control } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const CerrarSession = () => {
    dispatch(removeToken())
    navigate('/')
  }

  return (
    <React.Fragment >
      <SwipeableDrawer 
        disableBackdropTransition={true}
        anchor="left" 
        open={anchor} 
        onOpen={() => control(true)}
        onClose={() => control(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width : `${WITH_OF_PANEL}%`
          }
        }}>
        <List style={{ paddingTop : 64 }}>
          <MainListItems AppBarVisible={anchor} control={control}/>
        </List>
        <Divider />
        <List>
          {SecondaryListItems}
        </List>
        <Divider />
        <List>
          <Nav onClick ={CerrarSession}>
            <ListItem>
              <ListItemIcon>
                <Logout/>
              </ListItemIcon>
              <ListItemText>
                Cerrar sesion
              </ListItemText>
            </ListItem>
          </Nav>
        </List>
      </SwipeableDrawer>
    </React.Fragment>
  );
}

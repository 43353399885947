import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import QA from '../../Images/register_mok.png'

export function PuestoCard(props) {

    let { plaza } = props
    let { nombrepuesto, notas } = plaza

    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardMedia
                className="cardmedia_puesto"
                component="img"
                alt="green iguana"
                height="140"
                image={QA}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {nombrepuesto}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {notas}
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small"
                    onClick={() => props.toggle("VER", plaza)}>
                    Ver
                </Button>
                <Button size="small"
                    onClick={() => props.toggle("EDIT",plaza)}>
                    Editar
                </Button>
                <Button size="small"
                    onClick={() => console.log("eliminar plaza")} >
                    Eliminar
                </Button>
            </CardActions>
        </Card>
    );
}

import { Row, Container } from 'react-bootstrap'

export function Default() {

  return (

    <section>
      <div className="content-section">
        <div className="main">
          <Container >
            <Row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: "center" }}></Row>
          </Container>
        </div>
      </div>
    </section>
  )

}

import {  Col, Button, Table } from 'react-bootstrap'
import { Zoom, useMediaQuery as Size, TableCell  } from '@mui/material'

import ShowI from '@mui/icons-material/Grading';
import EditI from '@mui/icons-material/Edit';
import Deletei from '@mui/icons-material/Delete';
import {SinPuestos} from './SinPuestos'

export function ListMode(props) {

  const opciones = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
  let {  data, index, showing } = props;

  const isMovil = Size('(min-width:770px)');

  return(
    <Col md="12">
      <div className={!isMovil ? "body-table-responsive" : "body-table"}>
        <Table responsive borderless id="tablaproductos" style={{ color : 'white' }}>
          <thead>
            <tr >
              <th className="text-center">Nombre</th>
              <th className="text-center">Descripción</th>
              <th className="text-center">Creación</th>
              <th className="text-center">Acción</th>
            </tr>
          </thead>
          <tbody>
            {
              data.length && showing === 'list' ? 
                data[index].map( (plaza) => {
                  return (
                    <Zoom key={plaza.id} in={true}>
                      <tr >
                        <td className="text-center">{ plaza.nombrepuesto }</td>
                        <td className="text-center">{plaza.notas.length > 50 ? plaza.notas.slice(0,50) + "...." : plaza.notas }</td>
                        <td className="text-center">{ new Date( plaza.createday ).toLocaleDateString('es-GT',opciones) }</td>
                        <TableCell
                          sx={{
                            "display":'flex',
                            "flexDirection":'row',
                            "justifyContent": "space-between !important",
                            "flexWrap": !isMovil ? "wrap": "nowrap",
                          }} 
                          className="actions_butons_plaza"> 
                          <Button 
                            onClick={() => props.toggle("VER", plaza)}
                            className="ver_producto">
                            {!isMovil ? <ShowI/> : "Ver"}
                          </Button>
                          <Button 
                            onClick={() => props.toggle("EDIT", plaza)}
                            className="editar_producto">
                            {!isMovil ? <EditI/> : "Editar"}
                          </Button>
                          <Button 
                            onClick={() => {
                              console.log("ELIMINAR")
                            }} 
                            className="eliminar_producto">
                            {!isMovil ? <Deletei/> : "Eliminar"}
                          </Button>
                        </TableCell>
                      </tr>
                    </Zoom>
                  )
                }) : <SinPuestos toggle={props.new_puesto}/>
            }
          </tbody>
          <tfoot>
            <tr>
              <th className="text-center">Nombre</th>
              <th className="text-center">Descripción</th>
              <th className="text-center">Creación</th>
              <th className="text-center">Acción</th>
            </tr>
          </tfoot>
        </Table>
      </div>
    </Col>
  )
}

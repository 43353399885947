import React from 'react';
import { ThemeProvider, styled, createTheme } from '@mui/material/styles';

import {
  Container, IconButton, Divider,
  Typography, List, Toolbar, useMediaQuery as Size,
  Box, Badge, Menu, Avatar, MenuItem
} from '@mui/material'

import {
  Fullscreen as FullscreenIcon,
  Menu as MenuIcon,
  KeyboardDoubleArrowLeft as LeftKey,
  Notifications as NotificationsIcon,
} from '@mui/icons-material'
import jwt_decode from "jwt-decode";

import Logo from '../Images/evaluacion.jpeg';
import { stringAvatar } from '../Utils/avatar'

import { Outlet, useNavigate } from "react-router-dom";
import { MenuMovil } from '../Components/Navigation/MenuMovil';
import Footer from "../Components/Footer";

import { Drawer as MuiDrawer, AppBar as MuiAppBar } from "../Components/Navigation/AppBar"
import { MainListItems, SecondaryListItems } from '../Components/Navigation/listItems';
// import ProfilePicture from '../Images/man.png';
import { useDispatch, useSelector } from 'react-redux';
import { removeToken } from '../Slices/tokenSlice';

const drawerWidth = 240;
const mdTheme = createTheme();


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer,
  { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
          overflowX: 'hidden',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          width: theme.spacing(7),
          [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
          },
        }),
      },
    }),
  );

function DashboardContent() {

  const [open, setOpen] = React.useState(false);

  const isMovil = Size('(max-width:1000px)');
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const profile = useSelector((state) => state.recluter.info)
  const auth = useSelector((state) => state.token.token)

  const CerrarSession = () => {
    dispatch(removeToken())
    navigate('/')
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open_profile = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null)
  const toggleDrawer = () => {
    if (isMovil) {
      setAnchorElMov(!anchorElMovil)
    } else {
      setOpen(!open);
    }
  }
  const [anchorElMovil, setAnchorElMov] = React.useState(false);

  React.useEffect(() => { 
    if(isMovil){
      setOpen(false)
    }

    const token = auth
    const { exp } = jwt_decode(token);
    let timestamp = exp * 1000;
    let exp_in = timestamp - Date.now();
    // console.log("EXP IN : ", exp_in)
    if(exp_in <= 0 ){
      alert("TOKEN EXPIRADO, PORFAVOR INICIA SESSION")
      dispatch(removeToken())
    }

    setTimeout(() => {
      alert("TOKEN EXPIRADO, PORFAVOR INICIA SESSION")

    }, exp_in)

  }, [isMovil,auth, dispatch])

  return (
    <ThemeProvider theme={mdTheme}>
      <MenuMovil anchor={anchorElMovil} control={setAnchorElMov} />
      <Box sx={{ display: 'flex' }}>
        <AppBar style={{ backgroundColor: '#fff', boxShadow: 'None' }} position="absolute" open={open}>
          <Toolbar sx={{ pr: '24px', borderBottom: "1px solid #ec5e69" }} >
            <IconButton
              name="navigation_icon_button"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{ marginRight: '36px', ...(open && { display: 'none' }), }} >
              <MenuIcon style={{
                background: '#ec5e69',
                fontSize: "40",
                color: "#fff"
              }} />
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }} >
              {
                open ? (
                  <React.Fragment>

                    <IconButton onClick={toggleDrawer}>
                      <LeftKey />
                    </IconButton>

                    <IconButton onClick={(event) => event.target.requestFullscreen()}>
                      <FullscreenIcon style={{ paddinLeft: 15 }} />
                    </IconButton>
                  </React.Fragment>
                ) : undefined
              }
            </Typography>

            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>

              <IconButton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit">
                <Badge badgeContent={1} color="error">
                  <NotificationsIcon style={{ color: '#212529' }} />
                </Badge>
              </IconButton>


              <IconButton
                size="small"
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                aria-expanded={open_profile ? 'true' : undefined}
                onClick={handleClick}
                color="inherit"
              >
                {/* <Avatar alt="Cindy Baker" src={ProfilePicture} /> */}
                <Avatar {...stringAvatar(`${profile.nombre} ${profile.apelidos}`)} />

              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open_profile}
                onClose={handleClose}
                MenuListProps={{ 'aria-labelledby': 'basic-button', }}>
                <MenuItem onClick={() => navigate('/dashboard/configuraciones')}>Perfil</MenuItem>
                <MenuItem onClick={CerrarSession}>Cerrar Sesion</MenuItem>
              </Menu>
            </Box>


          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open} >
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              px: [1]
            }} >
            <div style={{ flat: 'righ' }} className="sidebar-header">
              <div className="width_img">
                <img src={Logo} alt="pruebas psicometricas" />
              </div>
            </div>
          </Toolbar>
          <Divider />
          <List>
            <MainListItems AppBarVisible={open} setAppBarVisible={setOpen} />
          </List>
          <Divider />
          <List>
            {SecondaryListItems}
          </List>
        </Drawer>
        <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
          <Outlet />
          <Footer />
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export function Dashboard() {
  return <DashboardContent />;
}


import React from 'react'
import { Service } from '../../Utils/HTTP'
import { Question } from '../../Components/Test/Cleaver/Question.jsx'
import { Box, Button, LinearProgress, Backdrop, CircularProgress, } from '@mui/material'
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';
import { createTheme, ThemeProvider } from '@mui/material/styles';


function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 0 }}>
        <LinearProgress color="primary" variant="determinate" {...props} />
      </Box>
    </Box>
  );
}

const theme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#fd4b4b',
      darker: '#053e85',
    },
    secondary: {
      main: '#2ec5d3',
      darker: '#053e85',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
  },
});

export function Cleaver() {

  let { id } = useParams();
  const auth = useSelector((state) => state.token.candi);
  const cleaver = useSelector((state) => state.cleaver);
  const role = useSelector((state) => state.user.role);
  let navigate = useNavigate()

  // let step = cleaver ? Object.keys(cleaver).length : 0
  const [totalRespondidas, setRespondidas] = React.useState([]);
  const [totalPreguntas, setPreguntas] = React.useState([]);
  const [current, setCurrent] = React.useState(0);
  const [progress, setProgress] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const BadQuestion = () => toast.error("Escoge una respuesta en cada columna")
  const CalculateProgress = () => {
    let total_preguntas = totalPreguntas.length + totalRespondidas.length
    let x = totalRespondidas.length * 100 / (total_preguntas)
    setProgress(x.toFixed(2))
  }

  React.useEffect(() => {
    let rest = new Service(`/prueba/findid/${id}`)
    rest.get(auth.token)
      .then(({ data }) => {
        console.log(data.questions)
        setPreguntas(data.questions)
        setRespondidas(data.questions.slice(0, 1))
        setCurrent(0)
      }).catch(console.log)
  }, [id, auth]);

  const saveRespones = () => {
    setOpen(true)
    let { responses: userResp } = cleaver;

    let body = []

    Object.keys(userResp)
      .forEach( id => {
        let {A, B} = userResp[id]
        let more = {
          "respuestaid": A,
          "asignacionid": role.assingid,
          "anotacion1": 1,
          "anotacion2": 0,
          "anotacion3": 0
        }
        body.push(more)
        let less = {
          "respuestaid": B,
          "asignacionid": role.assingid,
          "anotacion1": 0,
          "anotacion2": 0,
          "anotacion3": 0
        }
        body.push(less)
        return { more,less }
      })

    let rest = new Service('/prueba/addrespuestas/opcionmultiple')
    rest.postQuery(body, auth.token)
      .then(() => {
        // console.log("DATA: ", data)
        toast.success("Tu examen ha sido guardado")
        setOpen(false)
        setTimeout(() => navigate("/user/home"), 1000)

      })
      .catch(_e => {
        toast.error("Error al guardar el examen")
        setOpen(false)
      })

  }

  const handleAddQuestion = () => {
    let currentAnswer = totalRespondidas[totalRespondidas.length - 1];
    let current_resp = cleaver.responses[currentAnswer.id];

    if (!current_resp) {
      return BadQuestion();
    }
    if (parseInt(current_resp.A) === 0 || parseInt(current_resp.B) === 0) {
      return BadQuestion();
    }
    const nextHiddenItem = totalPreguntas.filter(({ id }) => id !== currentAnswer.id);
    if (nextHiddenItem) {
      setPreguntas(nextHiddenItem);
      setCurrent(current + 1);
      let temp = nextHiddenItem.shift()
      setRespondidas([...totalRespondidas, temp]);
    }
    CalculateProgress()
  };

  const handleRemoveQuestion = () => {
    let ultimaRespondida = totalRespondidas[totalRespondidas.length - 1];
    setPreguntas([ultimaRespondida, ...totalPreguntas]);
    let current_without_last = totalRespondidas.filter(({ id }) => id !== ultimaRespondida.id);
    setRespondidas([...current_without_last]);
    setCurrent(current - 1)
    CalculateProgress()
  }



  return (
    <ThemeProvider theme={theme}>
      <div className="content-section question">
        <div style={{ marginTop: 35 }}>
          <LinearProgressWithLabel value={progress ? parseInt(progress) : 0} />
        </div>
        <Box className="question_body">
          {totalRespondidas.map((item, i) => (
            <Question
              key={item.id}
              id={item.id}
              quiz={item}
              index={i}
              current={current}
            />)
          )}
        </Box>

        {
          totalPreguntas.length > 0 ?
            (
              <div className="question_btn">
                <Button
                  className="nextquestion_btn"
                  sx={{ backgroundColor: 'var(--main)' }}
                  variant="contained"
                  disabled={totalRespondidas.length <= 1}
                  onClick={handleRemoveQuestion}
                >
                  Anterior
                </Button>
                <Button
                  className="nextquestion_btn"
                  sx={{ backgroundColor: 'var(--main)' }}
                  variant="contained"
                  disabled={totalPreguntas.length <= 0}
                  onClick={handleAddQuestion}
                >
                  Siguiente
                </Button>
              </div>
            ) : (

              <div className="question_btn">
                <Button
                  className="nextquestion_btn"
                  sx={{ backgroundColor: 'var(--main)' }}
                  variant="contained"
                  onClick={saveRespones}
                >
                  Guardar
                </Button>
              </div>
            )

        }
        <Toaster position="bottom-right" />
        <Backdrop
          sx={{ color: '#fd4b4b', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        // onClick={()}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </ThemeProvider>
  )

}



import axios from 'axios';

export class Service {

  constructor(path) {
    // this.base_url = 'http://204.48.25.93:8081'
    this.base_url = 'https://evaluacionline.com:8444'
    this.url = this.base_url + path
    // this.api =  'http://204.48.25.93:8081/user?user=patrik&password=12345'
    // this.api =  'http://psicoadmin.ditca.org:8081/user?user=patrik&password=12345'
  }

  async getBlob(token) {
    return await axios({
      url: this.url,
      method: 'get',
      responseType: 'blob',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
  }


  async getQuery(token) {
    return await axios.get(this.url, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
  }

  async getXHR(token) {

    if (!token) {
      let response = await axios({
        method: "GET",
        url: this.url,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return await response.data;
    }


    const MyHeaders = {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json',
    }

    let response = await axios({
      method: "GET",
      url: this.url,
      headers: MyHeaders,
    })
    // console.log('response', response)

    return await response.data;

  }

  async get(token) {
    return axios.get(this.url, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log("ERROR :: ", error)
        return new Error("GG");
      })
  }

  async post(body, token) {

    if (!token) {
      let response = await axios({
        method: "POST",
        url: this.url,
        headers: {
          'Content-Type': 'application/json',
        },
        data: body
      })
      return await response.data;
    }


    const MyHeaders = {
      'Authorization': 'Bearer ' + token,
    }

    let response = await axios({
      method: "POST",
      url: this.url,
      headers: MyHeaders,
      data: body
    })
    console.log('response', response)

    return await response.data;

  }

  async postQuery(body, token) {

    if (!token) {
      return await axios({
        method: "POST",
        url: this.url,
        headers: {
          'Content-Type': 'application/json',
        },
        data: body
      })
    }


    const MyHeaders = {
      'Authorization': 'Bearer ' + token,
    }

    return await axios({
      method: "POST",
      url: this.url,
      headers: MyHeaders,
      data: body
    })


  }

  async put(body, token) {

    if (!token) {
      let response = await axios({
        method: "PUT",
        url: this.url,
        headers: {
          'Content-Type': 'application/json',
        },
        data: body
      })
      return await response.data;
    }


    const MyHeaders = {
      'Authorization': 'Bearer ' + token,
    }

    let response = await axios({
      method: "PUT",
      url: this.url,
      headers: MyHeaders,
      data: body
    })
    console.log('response', response)

    return await response.data;

  }


  async putQuery(body, token) {
    if (!token) {
      return await axios({
        method: "PUT",
        url: this.url,
        headers: {
          'Content-Type': 'application/json',
        },
        data: body
      })
    }

    const MyHeaders = {
      'Authorization': 'Bearer ' + token,
    }

    return await axios({
      method: "PUT",
      url: this.url,
      headers: MyHeaders,
      data: body
    })
  }



}


import { createSlice } from '@reduxjs/toolkit';

const initialState = { responses : {} }

export const cleaverSlice = createSlice({
  name : 'cleaver',
  reducers : {
    setResponse : (state, action) => {
      state.responses = { ...state.responses, ...action.payload  }
    }
  },
  initialState
})

export const { setResponse } = cleaverSlice.actions;
export default cleaverSlice.reducer;

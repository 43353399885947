import React, { useState, useEffect } from 'react';
import UpdateIcon from '@mui/icons-material/Update';
import { Typography, Pagination, Stack, } from '@mui/material'
import { Row, Col } from 'react-bootstrap';
import { Candidato, Preview } from './Candidato'

function Divide(arregloOriginal) {

  const LONGITUD_PEDAZOS = 7;
  let arregloDeArreglos = [];
  for (let i = 0; i < arregloOriginal.length; i += LONGITUD_PEDAZOS) {
    let pedazo = arregloOriginal.slice(i, i + LONGITUD_PEDAZOS);
    arregloDeArreglos.push(pedazo);
  }
  return arregloDeArreglos
}

function fromBase64(text) {
  try {
    return atob(text)
  } catch (_e) {
    return text
  }
}

export default function Candidatos(props) {

  let { passwords, setPassword, setVisible} = props;
  const [page, setPage] = useState(1);
  const [users, setUser] = useState([]);

  const changePage = (_, value) => {
    let page_numer = value;
    Divide(users)
    setPage(page_numer);
  };

  useEffect(() => {
    let list = passwords.map(pwd => {
      let { candidatospwds } = pwd
      return {
        password: fromBase64(pwd.pwd),
        candidatos: candidatospwds,
        pwd: pwd.pwd,
        plz: pwd.plaza_id
      }
    })

    let divided = Divide(list);
    setUser(divided)

  }, [passwords])

  return (
    <div className="body_historial">
      <div className="header_historial">
        <UpdateIcon style={{ color: "white", fontSize: 25, margin: 0, marginRight: 15, marginLeft: 15 }} />
        <p className="titlie_main">HISTORIAL DE ACCESO DE CANDIDATOS</p>
      </div>
      <div className="content_historial">
        <div className="cabeceras">
          <Row className="cabeceras_row">
            <div className="col20 "><p>Contraseña</p></div>
            <div className="col20 "><p>Usuarios</p> </div>
            <div className="col20 "><p>Asignaciones</p> </div>
          </Row>
        </div>
        {
          users.length
            ? users[page - 1].map((user, i) => (
              <Candidato 
                onClick={() => {
                  setPassword(user) 
                  setVisible(true) 
                }}
                key={i} 
                user={user} 
              />
            ))
            : <Preview style={{ paddingTop: 10 }} lenght={10} />
        }
        <Row style={{ padding: 5 }}>
          <Col>
            <Stack style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', justifyContent: 'space-evenly' }} spacing={2}>
              <Typography style={{ paddingTop: 15, paddingRight: 10 }}>Página: {page}</Typography>
              <Pagination
                siblingCount={1}
                boundaryCount={1}
                shape='rounded'
                count={users.length}
                page={page}
                onChange={changePage}
              />
            </Stack>
          </Col>
        </Row>
      </div>
    </div>
  )
}

import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import Logo from '../../Images/evaluacion.jpeg'
import Image from 'react-bootstrap/Image'
// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
}));

// ----------------------------------------------------------------------

AuthLayout.propTypes = {
  children: PropTypes.node
};

export default function AuthLayout({ children }) {
  return (
    <HeaderStyle>
      <RouterLink to="/">
        <Image style={{ width: '85%' }} fluid={true} alt="register logo" src={Logo} />
      </RouterLink>

      <div>
        <Typography
          variant="body2"
          sx={{
            mt: { md: -2 }
          }}
        >
          {children}
        </Typography>
      </div>
    </HeaderStyle>
  );
}

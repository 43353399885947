import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import { Service } from '../Utils/HTTP';
import { useSelector } from 'react-redux';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import { Loading } from '../Components/Generics/loading'
import { TestDataTable } from '../Components/Resultados/TestDataTable'
import DownloadIcon from '@mui/icons-material/Download';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import '../pdf.css'
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
//
//
import {
  Button, Box, Paper, LinearProgress,
  Typography, Dialog, DialogContent, DialogTitle
} from '@mui/material'

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const MyDocument = (props) => {

  let { pdf } = props;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    console.log('numero de pagians', numPages)
    setNumPages(numPages);
  }

  const Download = () => {
    let url = `/report/cleaverResult/${props.id}?output=pdf`
    let rest = new Service(url);

    rest
      .getBlob(props.token)
      .then(blob => {
        var file = window.URL.createObjectURL(blob.data);
        window.location.assign(file);
      })
      .catch(error => console.log({ error }))
  }

  const [progress, setProgress] = useState(10);

  return (
    <div>

      {
        progress < 100 ?
          <LinearProgressWithLabel value={progress} />
          : null
      }

      <div className="pdf_controls">


        <div className="btn_pdfcontrol">
          <Button
            className="btnmain"
            style={{ margin: 2 }}
            variant="contained"
            onClick={() => pageNumber > 1 ? setPageNumber(pageNumber - 1) : null} >
            Anterior
          </Button>
          <Button
            className="btnmain"
            style={{ margin: 2 }}
            variant="contained"
            onClick={() => pageNumber + 1 <= numPages ? setPageNumber(pageNumber + 1) : null} >
            Siguiente
          </Button>
        </div>

        <div>
          <b style={{ marginRight: 15 }}>
            Página {pageNumber} de {numPages}
          </b>

          <Button
            className="btnmain"
            variant="contained"
            onClick={Download} >
            <DownloadIcon />
          </Button>
        </div>

      </div>

      <div className="Example__container">
        <div className="Example__container__document">
          {pdf ?
            (<Document
              renderMode="canvas"
              file={pdf?.data}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={Loading}
              onLoadProgress={({ loaded, total }) => {
                setProgress((loaded / total) * 100)
              }}
            >
              <Page loading={Loading} pageNumber={pageNumber} />
            </Document>) : null
          }
        </div>
      </div>
    </div>
  )
};


export function Resultados() {

  let { id } = useParams();
  let auth = useSelector((state) => state.token)
  let [pdf, setPdf] = useState(null);
  let [idResult, setIDResult] = useState(id);

  useEffect(() => {
    console.log("ID:", idResult)

    if (!idResult) {
      console.log('no hay id')
      return
    }
    let url = `/report/cleaverResult/${idResult}?output=pdf`
    let rest = new Service(url);

    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    rest
      .getBlob(auth.token)
      .then(blob => setPdf(blob))
      .catch(error => console.log({ error }))

  }, [auth, idResult])

  return (
    <div className="content-section">
      <div className="main">
        <Box sx={{ width: '100%' }}>
          <Paper
            elevation={2}
            sx={{ mb: 2, padding: 2, height: '100%', minHeight: '95vh', boxShadow: 'none !important' }}>
            <h1>Reporte de Resultados</h1><hr />
            <TestDataTable setId={setIDResult} />
          </Paper>
        </Box>
      </div>

      <Dialog open={pdf !== null} fullWidth maxWidth="lg" onHide={() => setPdf(null)}>
        <DialogTitle>Reporte de Resultados
          <button type="button" className="close" onClick={() => setPdf(null)}>&times;</button>
        </DialogTitle>
        <DialogContent>
          {
            pdf ?
              (<MyDocument token={auth.token} id={id} pdf={pdf} />) : null
          }
        </DialogContent>
      </Dialog>

    </div>
  )
}

import ProfilePicture from '../Images/man.png';
import { Col, Row } from 'react-bootstrap'

export function Profile () {
    return (
        <div className="content-section">
            <div className="main">
                <h1 className="breadcrumb-header">Perfil</h1>
                <Row>
                    <Col md="12">
                        <div className="panel">
                            <Row className="row">
                                <Col md="4">
                                    <div className="img-container">
                                        <img alt="" src={ProfilePicture}/>
                                    </div>
                                </Col>
                                <Col md="8">
                                    <div className="custom-file-upload">
                                        <input type="file" id="file" name="myfiles[]" multiple readOnly />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <div className="inputs_config">
                                        <p>Datos personales</p>
                                        <label>Nombre</label>
                                        <input type="text" name="nombre_user" placeholder="Fernando Vásquez"/>
                                        <label>Puesto / Profesión</label>
                                        <input type="text" name="puesto" placeholder="Gerente general"/>
                                        <label>Dirección</label>
                                        <input type="text" name="direccion" placeholder="Melbourn, Australia"/>
                                        <label>Número</label>
                                        <input type="number" name="numero" placeholder="12345678"/>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="inputs_config">
                                        <p>Redes sociales</p>
                                        <label>Facebook</label>
                                        <input type="text" name="facebook" placeholder="Link de Facebook"/>
                                        <label>Linkein</label>
                                        <input type="text" name="linkedin" placeholder="Link de Linkedin"/>
                                        <label>Instagram</label>
                                        <input type="text" name="instagram" placeholder="Link de Instagram"/>
                                        <label>Correo</label>
                                        <input type="email" name="instagram" placeholder="Correo electrónico"/>
                                    </div>
                                </Col>
                            </Row>
                            <div className="boton_guardar_info">
                                <div className="success_btn">
                                    <a href="/">Guardar</a>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

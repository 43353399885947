import { Typography, Link } from '@mui/material'

export default function Footer(props) {
    return (
        <Typography style={{ paddingLeft : 25 }} className="footerinfo" variant="body2" color="text.secondary" align="center" {...props}>
            <Link>LICENCIA DE USO</Link>
            <Link>DERECHOS</Link>
            <Link>PRIVACIDAD</Link>
            <Link color="inherit" href="#">
                Propiedad de Grupo DIT {new Date().getFullYear()}
            </Link>
        </Typography>
    );
}

export function Copyright(props){
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="#">
                GrupoDIT
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

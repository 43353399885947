import * as React from 'react';
import {
    Card, CardActions, CardContent, Button, Typography,
    CardMedia, CardActionArea,
} from '@mui/material'

import QA from '../../Images/puesto.jpg'

export function TestCard(props) {

    let { test } = props;

    return (
        <Card className="card_test" elevation={0} sx={{ maxWidth: 500, maxHeight : 500 }}>
            <CardActionArea>
                <CardMedia
                    component="img"
                    height="140"
                    image={QA}
                    alt="green iguana"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                       {test.nombre}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {test.descripcion}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button size="small" color="primary">
                      Realizar
                </Button>
            </CardActions>
        </Card>
    )

}

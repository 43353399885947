import React from 'react'
import { Routes, Route, Navigate } from "react-router-dom";

import { Dashboard } from "./Dashboard";
import { User } from "./User";
import { Login } from '../Pages/Login'
import { LoginCs } from '../Pages/Logincs'
import { Register } from '../Pages/Register'
import { Home } from '../Pages/Home'
import { Puestos } from '../Pages/Puestos'
import { Contrasv2 as Contras } from '../Pages/ContrasV2'
import { Expedientes } from '../Pages/Expedientes'
import { Resultados } from '../Pages/Resultados'
import { Configuracion } from '../Pages/Configuracion'
import { Historial } from '../Pages/Historial'
import { Pruebas } from '../Pages/Pruebas'
import { PruebaNueva } from '../Pages/PruebaNueva'
import { PruebaAsignar } from '../Pages/PruebaAsignar'
import { NotFound } from '../Pages/404'
import { Profile } from '../Pages/Profile'
import { RestorePassword } from '../Pages/RestorePassword'

import { HomeUser } from '../Pages/HomeUser'
import { Prueba } from '../Pages/Prueba.jsx'
import { RequireToken, RequireUserToken } from '../Components/PrivateRoute'
import { Default } from '../Pages/Default';

export default function MyRoutes() {

  return (
    <Routes>

      <Route path="/" element={<Navigate to='/login' />} />
      <Route path="login" element={<Login />} />
      <Route path="logincd" element={<LoginCs />} />
      <Route path="logincd/:pwd/:usuario" element={<LoginCs />} />
      <Route path="password/recuperar" element={<RestorePassword />} />
      <Route path="register" element={<Register />} />

      <Route
        path="user"
        element={
          <RequireUserToken>
            <User />
          </RequireUserToken>
        } >
        <Route path="default" element={<Default />} />
        <Route path="default2" element={<Default />} />
        <Route path="home" element={<HomeUser />} />
        <Route path="pruebas" element={<HomeUser />} />
        <Route path="prueba">
          <Route path=":id" element={<Prueba />} />
          <Route path="" element={<Prueba />} />
        </Route>
      </Route>


      <Route
        path="dashboard"
        element={
          <RequireToken>
            <Dashboard />
          </RequireToken>
        }>

        <Route path="" element={<Home />} />
        <Route path="home" element={<Home />} />
        <Route path="puestos" element={<Puestos />} />
        <Route path="perfil" element={<Profile />} />
        <Route path="contrasenas" element={<Contras />} />
        <Route path="expedientes" element={<Expedientes />} />

        <Route path="resultados" >
          <Route path=":id" element={<Resultados />} />
          <Route path="" element={<Resultados />} />
        </Route>

        <Route path="configuraciones" element={<Configuracion />} />
        <Route path="historial" element={<Historial />} />
        <Route path="pruebas/listar" element={<Pruebas />} />
        <Route path="pruebas/crear" element={<PruebaNueva />} />
        <Route path="pruebas/aplicar" element={<PruebaAsignar />} />
        <Route path="default" element={<Default />} />
        <Route path="default2" element={<Default />} />

      </Route>
      <Route path="*" element={<NotFound />} />

    </Routes>
  )
}


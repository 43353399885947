import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { PersonOutline, VerifiedUser, ListAlt } from '@mui/icons-material/'
// import Actividades from '../Components/Home/Actividades'
import Candidatos from '../Components/Home/Candidatos'
import { Card } from '../Components/Card';
import { useSelector } from 'react-redux';
import { Service } from '../Utils/HTTP.js'
import { useQuery } from 'react-query';
import { ModalEdit } from '../Components/Password/Operation';

export function Home() {

  const auth = useSelector((state) => state.token)
  const [password,setPasword]= useState(null)
  const [visible,setVisible]= useState(false)
  const hideModal = () => setVisible(false)

  const getAllPwd = async () => {
    let rest = new Service('/contrasenia/getallbyidUsr');
    return await rest.getQuery(auth.token)
  }

  const { data } = useQuery('homepwd', getAllPwd);

  return (
    <section >
      <div className="content-section">
        <div className="main">
          <div className="panel_options">
            <Row>
              <Col md="4">
                <Card
                  quantity={data?.data?.length || 0} 
                  to='/dashboard/contrasenas' 
                  title='CONTRASEÑAS' 
                  icon={VerifiedUser} 
                />
              </Col>
              <Col md="4">
                <Card
                  quantity={0} 
                  to='/dashboard/expedientes'
                  title='EXPEDIENTES' 
                  icon={PersonOutline} />
              </Col>
              <Col md="4">
                <Card
                  quantity={0} 
                  to="/dashboard/puestos" 
                  title='PUESTOS' 
                  icon={ListAlt} />
              </Col>
            </Row>
          </div>
          <div className="historial_candidatos">
            <Row>
              <Col md="12">
                <Candidatos 
                  setPassword={setPasword}
                  setVisible={setVisible}
                  passwords={data?.data || []}
                />
              </Col>
              {/*
                <Col md="4">
                  <Actividades />
                </Col>
              */}
            </Row>
          </div>
        </div>

        { password ?
          <ModalEdit
            home={true}
            password={password}
            open={visible}
            handleOpen={hideModal}
          />: null
        }

      </div>
    </section>
  )

}


import { useEffect, useState } from 'react'
import { Container, Row, Card } from 'react-bootstrap'
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

function CardChar (props){
    let { image, name } = props.proto
    return(
        <Card onClick={ () => props.remove(props.id) } style={{ width: '18rem' }}>
            <Card.Img variant="top" src={image} />
            <Card.Body>
                <Card.Title>{name}</Card.Title>
            </Card.Body>
        </Card>
    )
}

function Preview (){
    return(
        <div>
            <Stack spacing={1}>
                <Skeleton variant="text" />
                <Skeleton variant="circular" width={40} height={40} animation="wave" />
                <Skeleton variant="rectangular" width={210} height={118} animation="wave" />
            </Stack>
            <Stack spacing={1}>
                <Skeleton variant="text" />
                <Skeleton variant="circular" width={40} height={40} animation="wave"/>
                <Skeleton variant="rectangular" width={210} height={118}animation="wave" />
            </Stack>
            <Stack spacing={1}>
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="circular" width={40} height={40}  animation="wave"/>
                <Skeleton variant="rectangular" width={210} height={118} animation="wave"/>
            </Stack>
        </div>
    )
}



export function Historial () {

    let [character, setCharacter] = useState([])
    let [elements, setElements] = useState(0)

    useEffect(() => {

        if(!character.length){
            fetch('https://rickandmortyapi.com/api/character')
                .then( response => response.json() )
                .then( data => {
                    let { results } = data;
                    console.log('RES ',results)
                    setCharacter(results)
                    setElements(results.length)
                })
        }

        console.log('Render', character, elements)

    }, [elements, character])


    const Remove = (index) =>{
        console.log('start leng => ', character.length)
        let c = character.splice(index, 1)
        console.log('result -> ',c.length)
        console.log('character -> ',character.length)
        setCharacter(character)
        setElements(character.length)
    } 
    

    return(
        <section>
            <div className="content-section">
                <div className="main">
                    <Container >
                        <Row style={{ display : 'flex', flexWrap : 'wrap', justifyContent: "center" }}>
                            {
                                !character.length ?
                                    <Preview/>
                                    :
                                    character.map( (person, i) => <CardChar remove={Remove} key={i} proto={person} id={i}/> )
                            }
                        </Row>
                    </Container>
                </div>
            </div>
        </section>
    )

}


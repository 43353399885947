import { useEffect, useState } from 'react'
import { Service } from '../../../Utils/HTTP.js'
import { useSelector } from 'react-redux'
import {
  FormControl, RadioGroup, FormControlLabel, FormLabel, Checkbox,
  TextField, Box, FormGroup, Button, Stack, Collapse
} from '@mui/material'
import toast, { Toaster } from 'react-hot-toast';

// import { Simple as Loading } from '../../Generics/loading.jsx'

const ROLE = {
  ADMIN: 1,
  ASISTENTE: 2,
  CANDIDATO: 3,
}

function PasswordInfoForm({ setUser, user }) {

  return (
    <Stack >
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} >

        <TextField
          value={user?.email}
          onChange={(event) => {
            let value = event.target.value
            setUser({ ...user, ...{ email: value } })
          }
          }
          fullWidth
          id="outlined-basic"
          label="Correo"
          variant="outlined"
        />

        <TextField
          value={user?.password}
          onChange={(event) => {
            let value = event.target.value
            setUser({ ...user, ...{ password: value } })
          }
          }
          fullWidth
          id="outlined-basic"
          label="Contraseña"
          variant="outlined"
        />

      </Stack>
    </Stack>
  );
}


function validateMail(email) {
  let re = /\S+@\S+\.\S+/;
  return re.test(email);
}

function validatePassword(password) {
  let re = /[A-Za-z0-9]{8,}/;
  return re.test(password);
}


function PermisosList(props) {

  let { recursos, selectedRole, setSelectedRole } = props
  if(recursos.data.length === 0) return (<></>);

  return (
    <FormControl className="rolelist" >
      <FormLabel className="demo-radio-buttons-group-label">{recursos.label}</FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
      >
        {


            recursos.data &&
            recursos.data.map((r) => {
              return (
                <FormControlLabel
                  value={r.id}
                  onChange={(event) => {
                    let { checked } = event.target
                    setSelectedRole((prev) => {
                      if (checked) {
                        return [...prev, r.id]
                      } else {
                        return prev.filter((p) => p !== r.id)
                      }
                    })
                  }}
                  checked={selectedRole.includes(r.id)}
                  control={<Checkbox />}
                  label={r.nombre}
                />
              )
            })
        }
      </RadioGroup>
    </FormControl>
  );
}

function TipoUsuarios(props) {

  let { type, setType } = props

  const handleChange = (event) => setType(parseInt(event.target.value));

  return (
    <FormControl>
      <FormLabel className="demo-radio-buttons-group-label">Nivel de Acceso</FormLabel>
      <FormGroup
        className="seluser_type"
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={type}
        onChange={handleChange}
      >
        <FormControlLabel
          value={ROLE.CANDIDATO}
          onChange={handleChange}
          checked={type === ROLE.CANDIDATO}
          control={<Checkbox />}
          label="Candidato"
        />
        <FormControlLabel
          value={ROLE.ADMIN}
          onChange={handleChange}
          checked={type === 1}
          control={<Checkbox />}
          label="Administrador"
        />
        <FormControlLabel
          value={ROLE.ASISTENTE}
          onChange={handleChange}
          checked={type === 2}
          control={<Checkbox />}
          label="Asistente"
        />
      </FormGroup>
    </FormControl>
  );
}


export function TypePwd(props) {
  // console.log(props)

  const auth = useSelector(state => state.token)
  const profile = useSelector(state => state.user.profile)
  const [recursos, setRecursos] = useState(null)
  const [selectedRole, setSelectedRole] = useState([])
  const [userType, setUserType] = useState(ROLE.CANDIDATO)
  const [user, setUser] = useState(null)

  const postUser = async (body) => {
    try {
      let rest = new Service('/addSubUser')
      let req = {
        "username": body.email,
        "nombre": body.email,
        "apelidos": body.email,
        "decription": "somthing",
        "pwd": body.password,
        "idEmpresa": profile.id,
      }
      let response = await rest.post(req, auth.token)
      return response.id || 0;
    } catch (e) {
      console.log('CATCH:', e)
      return 0;
    }
  }

  const saveUser = async () => {
    //TODO: trigger form submit
    if (user === null || !user || user === undefined) return toast.error('Falta informacion del usuario')

    let { email, password } = user

    let validMail = validateMail(email)
    if (!validMail) return toast.error('Correo invalido')

    let validPwd = validatePassword(password)
    if (!validPwd) return toast.error('Contraseña invalida')

    if (userType === ROLE.ASISTENTE && selectedRole.length === 0) return toast.error('Selecciona al menos un rol')

    let id = await postUser(user)
    if (id === 0) return toast.error('Ocurrio un error al crear el usuario')
    toast.success('Usuario creado con exito')
    console.log("id:", id)
    if (userType === ROLE.ASISTENTE) postResources(id)
    if (userType === ROLE.ADMIN) adminResource(id)
    clearForm()

  }

  const adminResource = (user_id) => {
    let rest = new Service('/rolAdd/' + user_id)
    let admin_resouce = [
      {
        "id": "14",
        "path": "na"
      }
    ]
    let saved = rest.post(admin_resouce, auth.token)
    console.log('saved:', saved)
    
  }

  const postResources = (user_id) => {
    let rest = new Service('/rolAdd/' + user_id)
    let selected = selectedRole.map(id => ({ id: id, path: "" }))
    console.log('selected:', selected)
    let saved = rest.post(selected, auth.token)
    console.log('saved:', saved)
  }

  const clearForm = () => {
    setUser(null)
    setSelectedRole([])
    setUserType(ROLE.CANDIDATO)
  }


  useEffect(() => {

    const getRecursos = () => {
      let rest = new Service('/recursos/allow')
      return rest.getQuery(auth.token);
    }
    const groupRecursos = (recursos) => {
      console.log("resource:",recursos)
      let groups = {};
      recursos.forEach((r) => {
        if (groups[r.grupo]) {
          groups[r.grupo].push(r)
        } else {
          groups[r.grupo] = [r]
        }
      })
      console.log('grupos: ', groups)

      assignRecursos(groups)
    }

    const assignRecursos = (recursos_api) => {
      let templete = {
        1: { "label": 'Puestos', data: [] },
        // 2: { "label": 'Varios', data: [] },
        3: { "label": 'General', data: [] },
        4: { "label": 'Contraseñas', data: [] },
        5: { "label": 'Reportes', data: [] }
      }

      Object.keys(recursos_api)
        .forEach((k) => {
          if(templete[k]){
            templete[k].data = recursos_api[k];
          }
        })

      setRecursos(templete)
    }

    getRecursos()
      .then(({ data }) => groupRecursos(data))
      .catch((err) => console.log('ERROR:', err))

  }, [auth.token])

  return (
    <div className="gapwdrole">

      <div className="typepwdlist control_flow_role">
        <TipoUsuarios type={userType} setType={setUserType} />


        <Box sx={{ mb: 2 }}>
          <div style={{ paddingTop: 15 }}>
            <Button
              onClick={parseInt(userType) === ROLE.CANDIDATO ? props.handleNext : saveUser}
              className="registerBtn"
              variant="contained"
              sx={{ mt: 1, mr: 1 }}
            >
              {parseInt(userType) === ROLE.CANDIDATO ? 'Siguiente' : 'Guardar'}
            </Button>
            <Button
              disabled={true}
              onClick={props.handleBack}
              sx={{ mt: 1, mr: 1 }}
            >
              Regresar
            </Button>
          </div>
        </Box>

      </div>

      <Collapse orientation="vertical" in={parseInt(userType) === ROLE.ADMIN} >
        <div className="typepwdlist">
          <FormLabel className="demo-radio-buttons-group-label">Información del usuario</FormLabel>
          <PasswordInfoForm setUser={setUser} user={user} />
        </div>
      </Collapse>

      <Collapse orientation="vertical" in={parseInt(userType) === ROLE.ASISTENTE} >
        <div className="typepwdlist">
          <FormLabel className="demo-radio-buttons-group-label">Información del usuario</FormLabel>
          <PasswordInfoForm setUser={setUser} user={user} />
        </div>

        <div className="typepwdlist">
          <FormLabel className="demo-radio-buttons-group-label">
            Selecciona los privilegios
          </FormLabel>
          {
            recursos &&
            Object.keys(recursos).map((k) =>
              <PermisosList
                recursos={recursos[k]}
                selectedRole={selectedRole}
                setSelectedRole={setSelectedRole}
              />
            )}
        </div>
      </Collapse>

      <Toaster position="top-right" />


    </div>
  )

}

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './Css/all.css'
import { createContext } from 'react'
import { BrowserRouter as Router } from "react-router-dom";
import { Container } from 'react-bootstrap'
// import { AuthProvider } from './Auth/AuthProvider'
import { QueryClient, QueryClientProvider } from 'react-query'

import Routes from './Components/Routes'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry:false,
      cacheTime: 0,
    },
  },
})

const GATEWAY = {
  dev: {
    API: 'http://localhost:8000',
  },
  pro: {
    API: '',
  }
}

export const API_GATEWAY = createContext(GATEWAY)

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <API_GATEWAY.Provider value={GATEWAY.dev}>
          <Container fluid>
            <Routes />
          </Container>
        </API_GATEWAY.Provider>
      </Router>
    </QueryClientProvider>
  );
}

import React from 'react'
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';

import {
  Box, Button,
  Stack, TextField,
} from '@mui/material';


export function Company(props) {

  const RegisterSchema = Yup.object().shape({
    nombrecpmercial: Yup.string().min(2, 'Demasiado corto!').max(50, 'Demasiado largo!').required('El nombre es requerido'),
    telefono: Yup.number().min(10000000, 'El telefono es requerido').max(99999999, 'El telefono debe ser valido').required('El telefono es requerido'),
    decription: Yup.string().min(5, 'Ingresa una descripcion valida').required('Ingresa una descripcion valida'),
  });

  let { client, setClient, handleNext, handleBack } = props

  const formik = useFormik({
    initialValues: {
      nombrecpmercial: client?.empresa?.nombrecpmercial,
      telefono: client?.empresa?.telefono,
      decription: client?.empresa?.decription
    },
    onSubmit: (fields) => {
      setClient({
        ...client,
        empresa : fields
      })
      handleNext()
    },
    validationSchema: RegisterSchema,
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider style={{ padding: 15 }} value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

            <TextField
              label="Nombre Comercial"
              fullWidth
              {...getFieldProps('nombrecpmercial')}
              error={Boolean(touched.nombrecpmercial && errors.nombrecpmercial)}
              helperText={touched.nombrecpmercial && errors.nombrecpmercial}
            />

            <TextField
              label="Telefono"
              fullWidth
              {...getFieldProps('telefono')}
              error={Boolean(touched.telefono && errors.telefono)}
              helperText={touched.telefono && errors.telefono}
            />

          </Stack>

          <TextField
            label="Descripcion"
            fullWidth
            autoComplete="Descripcion"
            {...getFieldProps('decription')}
            error={Boolean(touched.decription && errors.decription)}
            helperText={touched.decription && errors.decription}
          />
          <Box sx={{ mb: 2 }}>
            <div style={{ paddingTop: 15 }}>
              <Button
                name="submit_second_step"
                type="submit"
                className="registerBtn"
                variant="contained"
                sx={{ mt: 1, mr: 1 }}
              >
                {'Siguiente'}
              </Button>
              <Button
                onClick={handleBack}
                sx={{ mt: 1, mr: 1 }}
              >
                Regresar
              </Button>
            </div>
          </Box>

        </Stack>
      </Form>
    </FormikProvider>
  );
}

import React from "react";
import {
  IconButton, Tooltip
} from '@mui/material';

import { Add as AddIcon } from '@mui/icons-material'
import { HelpModal as PasswordModal } from '../../Components/Modal/PasswordModal'

export default class CustomToolbar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      visible: false
    }
  }

  setVisible = () => this.setState({ visible: false });

  handleClick = () => {
    console.log("is visible  :", this.state.visible);
    this.setState({ visible: !this.state.visible })
  }

  render() {
    let { visible } = this.state;
    return (
      <React.Fragment>
        <Tooltip title={"custom icon"}>
          <IconButton onClick={this.handleClick}>
            <AddIcon />
          </IconButton>
        </Tooltip>
        <PasswordModal visible={visible} handleClose={this.setVisible} />
      </React.Fragment>
    );
  }

}


import * as React from 'react';
import { Remove as RemoveIcon, Add as AddIcon } from '@mui/icons-material';
import {
  Card,CardContent,Avatar,Checkbox,List,Tooltip,Fade,
  ListItem,ListItemButton,ListItemIcon,ListItemText,
} from '@mui/material'

import { deepPurple } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { setResponse  } from '../../../Slices/cleaverSlice';


function CheckboxexHeader(prop) {
  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <ListItem>
        <Avatar sx={{ bgcolor: deepPurple[500] }}>{prop.group}</Avatar>
        <ListItemButton role={undefined} dense>
          <ListItemText style={{marginRight :25, color:'silver'}} primary={prop.title} />
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemIcon>
            <RemoveIcon />
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
    </List>
  );
}

function CheckboxesGroup(props) {

  let { quiz, id : index } = props;

  let resp = useSelector((state) => state.cleaver.responses)

  const [checkA, setCheckA] = React.useState(resp[index]? resp[index].A : 0);
  const [checkB, setCheckB] = React.useState(resp[index]? resp[index].B : 0);
  const dispatch = useDispatch()

  const changeA = (event) => {
    let { id, checked } = event.target
    if(parseInt( checkB ) !== parseInt(id) && checked ){
      setCheckA(parseInt( id ))
      let temp = {
        [index]: {
          A:id,
          B: resp[index] ? resp[index].B : 0
        }
      }
      dispatch(setResponse(temp))
    }
  };
  
  const changeB = (event) => {
    let { id, checked } = event.target
    if(parseInt( checkA ) !== parseInt( id ) && checked){
      setCheckB(parseInt( id ))
      let temp = {
        [index]: {
          B:id,
          A: resp[index] ? resp[index].A : 0
        }
      }
      dispatch(setResponse(temp))
    }
  };

  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>

      {quiz.map((value) => {
        const labelId = `checkbox-list-label-${value.id}`;
        return (
          <ListItem key={value.id}>
            <ListItemButton dense>
              <Tooltip title={value.decription} placement="top-start" arrow>
                <ListItemText 
                  sx={{ 
                    '& .MuiTypography-root' : {
                      fontWeight:'bold'
                  }}} 
                  className="checkbox_label_question" 
                  id={labelId} 
                  primary={value.nombre} 
                 />
              </Tooltip>

              <ListItemIcon>
                <Checkbox
                  id={value.id.toString()}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                  color="error"
                  edge="start"
                  disableRipple
                  inputProps={{ 'aria-labelledby': value.id }}
                  checked={parseInt( checkA ) === parseInt( value.id )}
                  onChange={changeA}
                />
              </ListItemIcon>
              <ListItemIcon>
                <Checkbox
                  id={value.id.toString()}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                  color="error"
                  edge="start"
                  disableRipple
                  inputProps={{ 'aria-labelledby': value.id }}
                  checked={parseInt( checkB ) === parseInt( value.id )}
                  onChange={changeB}
                />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}

export function Question({quiz, index, current}) {
  let { instrucciondepregunta, respuestas,id } = quiz
  let checked = index === current;

  return (
    <Fade in={checked} unmountOnExit>
      <Card className="question_form">
        <CardContent>
          <div variant="body2">
            <List>
              <CheckboxexHeader group={index + 1} title={instrucciondepregunta}/>
            </List>
            <CheckboxesGroup 
              id={id}
              quiz={respuestas} 
              />
          </div>
        </CardContent>
      </Card>
    </Fade>
  );
}

import * as React from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, ButtonBase
} from '@mui/material'

import { DisabledByDefault } from '@mui/icons-material'

export function MailTable(props) {

  let { users, remove, home } = props;

  return (
    <TableContainer 
      style={{marginTop:'0px !important',marginBottom:'0px !important'}} 
    >
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className="headert_pwdu">Correo</TableCell>
            <TableCell className="headert_pwdu" align="right">Nombres</TableCell>
            <TableCell className="headert_pwdu" align="right">Apellidos</TableCell>
            <TableCell className="headert_pwdu" align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users ? users.map((row, i) => (
            <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
              <TableCell component="th" scope="row">
                {row.mail}
              </TableCell>
              <TableCell align="right">{row.nombres}</TableCell>
              <TableCell align="right">{row.apellidos}</TableCell>
              { !home?
                <TableCell align="right">
                  <ButtonBase>
                    <DisabledByDefault onClick={() => remove(row.mail)} style={{color:'var(--main)'}} />
                  </ButtonBase>
                </TableCell>: null
              }
            </TableRow>
          )) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import * as React from 'react';
import { Nav } from 'react-bootstrap';

// import { Fingerprint, ExpandLess, ExpandMore } from '@mui/icons-material/'
import { useNavigate, useResolvedPath, useMatch } from 'react-router-dom'
import { 
  ListItem, List, ListItemIcon, ListItemText, ListSubheader 
  // Collapse,
} from '@mui/material/'

import {
  MainItems, ExtraItems, TxTStyle,
  UserItems,
 // PruebaItems
} from '../../Utils/MenuItems'


function NavItem(props) {

  let navigate = useNavigate()
  let resolved = useResolvedPath(props.route);
  let match = useMatch({ path: resolved.pathname, end: false });

  let { title, route, icon, open, AppBarVisible, setOpen, control } = props

  if (route.startsWith('prueba') && match && open && !AppBarVisible) {
    setOpen(false);
  }

  return (
    <ListItem
      sx={{ color: '#25344f' }}
      selected={match && typeof (match) === "object"}
      onClick={() => {
        navigate(route) 
        if(control){
          control(false)
        }
      }}
      button >
      <ListItemIcon>
        {icon && icon}
      </ListItemIcon>
      <ListItemText
        sx={TxTStyle}
        primary={title}
      />
    </ListItem>
  )
}


export const MainListItems = (props) => {


  return (
    <List>
      <ListSubheader inset>MENÚ</ListSubheader>
      {
        MainItems.map(({ icon, title, route }, i) => (
          <NavItem control={props.control} key={i} icon={icon} title={title} route={route} />
        ))
      }

      {/*
        <ListItem selected={open} onClick={showPruebas}>
          <ListItemIcon>
            <Fingerprint />
          </ListItemIcon>
          <ListItemText
            sx={TxTStyle}
            primary="Pruebas"
          />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {
                PruebaItems.map(({ route, title }, i) =>
                  (<NavItem key={i} setOpen={setOpen} {...props} open={open} route={route} title={title} />)
                )
              }
            </List>
            </Collapse>
      */}

    </List>
  )
};

export const UserListItems = (_props) => {
  return (
    <List>
      <ListSubheader inset>MENÚ</ListSubheader>
      {
        UserItems.map(({ icon, title, route }, i) => (
          <NavItem key={i} icon={icon} title={title} route={route} />
        ))
      }
    </List>
  )
};

export const SecondaryListItems = (
  <Nav>
    <ListSubheader inset>EXTRAS</ListSubheader>
    {
      ExtraItems.map(({ icon, route, title }) =>
        (<NavItem key={route} icon={icon} title={title} route={route} />)
      )
    }
  </Nav>
);


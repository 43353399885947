import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

export function RequireUserToken({ children }) {

  let token = useSelector((state) => state.token.candi)
  let location = useLocation();

  if (!token) {
    return <Navigate to="/logincd" state={{ from: location }} replace />;
  }

  return children;
}


export function RequireToken({ children }) {

  let token = useSelector((state) => state.token.token)
  let location = useLocation();

  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

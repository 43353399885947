import React from 'react';
import {
  Home, Work, VisibilityOff,
  Equalizer, OndemandVideo, SupportAgent, Star,
  MiscellaneousServices,
  AssignmentTurnedIn
  //PeopleAlt, History
} from '@mui/icons-material'

export const UserItems = [
  {
    icon: <Home />,
    route: 'home',
    title: 'Inicio'
  },
  {
    icon: <AssignmentTurnedIn />,
    route: 'pruebas',
    title: 'Pruebas'
  },
  {
    icon: <Star />,
    route: 'prueba/:id',
    title: 'Test Actual'
  },
]


export const MainItems = [
  {
    icon: <Home />,
    route: 'home',
    title: 'Inicio'
  },
  {
    icon: <Work />,
    route: 'puestos',
    title: 'Puestos'
  },
  {
    icon: <VisibilityOff />,
    route: 'contrasenas',
    title: 'Contraseñas'
  },
  // {
  //     icon : <PeopleAlt/>,
  //     route : 'expedientes',
  //     title : 'Expedientes'
  // },
  {
    icon: <Equalizer />,
    route: 'resultados',
    title: 'Resultados'
  },
  {
    icon: <MiscellaneousServices />,
    route: 'configuraciones',
    title: 'Configuraciones'
  },
  // {
  //     icon : <History/>,
  //     route : 'historial',
  //     title : 'Historial'
  // },
]

export const ExtraItems = [
  // {
  //   icon: <Star />,
  //   route: 'elementos',
  //   title: 'Elementos'
  // },
  {
    icon: <OndemandVideo />,
    route: 'default',
    title: 'Tutoriales'
  },
  {
    icon: <SupportAgent />,
    route: 'default2',
    title: 'Asistencia Técnica'
  },
]

export const PruebaItems = [
  {
    route: "pruebas/crear",
    title: "Crear Prueba",
  },
  {
    route: "pruebas/listar",
    title: "Listado de pruebas",
  },
  {
    route: "pruebas/aplicar",
    title: "Aplicar",
  },
  {
    route: "pruebas/respuestas",
    title: "Respuestas",
  },
  {
    route: "pruebas/calificaciones",
    title: "Calificaciones",
  },
]

export const TxTStyle = {
  fontSize: 12,
  ' .css-10hburv-MuiTypography-root': {
    fontSize: '.875rem'
  },
}


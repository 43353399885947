import React from 'react'
import * as Yup from 'yup';
// import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';

import {
    Box, Button, Stack, Checkbox,
    TextField, Autocomplete,
} from '@mui/material';

import {
    CheckBox as CheckBoxIcon,
    CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon
} from '@mui/icons-material';

import { useQuery } from 'react-query';
import { Service } from '../../../Utils/HTTP.js'
import { useSelector } from 'react-redux'

const delete_icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


export function Puesto(props) {

  // console.log(props.password)

  const auth = useSelector((state) => state.token);

  const PlazaScheme = Yup.object().shape({
    puesto: 
    Yup.array()
    .required('Escoge un puesto valido')
    .min(1,"Debes escoger por lo menos un puesto")
    .max(1,"Debes escoger un solo puesto")
  });

  const getPuestos = async () => {
    let rest = new Service('/plaza/getall')
    return rest.getQuery(auth.token);
  }

  let { data } = useQuery('puestos', getPuestos)
  let puestos = data ? data.data.map( ({ nombrepuesto, id  }) => ({ nombrepuesto, id } )) : []

  let {  handleNext, handleBack, password, setPassword } = props

  const formik = useFormik({
    initialValues: {
      puesto: password.puesto
    },
    onSubmit: (fields) => {
      setPassword({
        ...password,
        puesto : fields.puesto
      })
      handleNext()
    },
    validationSchema: PlazaScheme,
  });

  const { errors, touched,handleSubmit, getFieldProps,setValues } = formik;

  return (
    <FormikProvider style={{ padding : 25 }} value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <Autocomplete
            disableCloseOnSelect={false}
            {...getFieldProps('puesto')}
            onChange={(_a,current_list,_c,_individual,_f) => {
              setValues({
                puesto:current_list
              })
            }}
            isOptionEqualToValue={(option, value) => option.nombrepuesto === value.nombrepuesto}
            multiple
            id="checkboxes-tags-demo"
            options={puestos}
            getOptionLabel={(option) => { 
              return option.nombrepuesto 
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={delete_icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                  />
                {option.nombrepuesto}
              </li>
            )}
            renderInput={(params) => (
              <TextField 
                // {...getFieldProps('puesto')}
                error={Boolean(touched.puesto && errors.puesto)}
                helperText={touched.puesto && errors.puesto}
                {...params} 
                label="Escribe el nombre del puesto" 
                placeholder="Puesto" 
                />
            )}
            />

          <Box sx={{ mb: 2 }}>
            <div style={{ paddingTop  : 15}}>
              <Button
                type="submit"
                className="registerBtn" 
                variant="contained"
                sx={{ mt: 1, mr: 1 }}
              >
                {'Siguiente'}
              </Button>
              <Button
                disabled={false}
                onClick={handleBack}
                sx={{ mt: 1, mr: 1 }}
              >
                Regresar
              </Button>
            </div>
          </Box>

        </Stack>
      </Form>
    </FormikProvider>
  );
}

import React from 'react';
import { Modal } from 'react-bootstrap'

export default function Mostrar(props) {

    let { visible, onClose } = props

    return(
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered  show={visible} onHide={onClose}>
            <Modal.Header>
                <button  onClick={onClose} type="button" className="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">Asistencia</h4>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div class="data_notification">
                    <p>En el menú Pruebas encontrarás la opción Asignar , utilízala para seleccionar las pruebas que le serán aplicadas al candidato. Selecciona el código del candidato y marca las pruebas, una vez marcadas presiona el botón Asignar para comenzar la aplicación de las mismas.</p>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export function ShowComportamiento() {
    // $("#comportamiento_body").css('display', 'block');
    // $("#personalidad_body").css('display', 'none');
    // $("#inteligencia_body").css('display', 'none');
    // $("#personalidad").removeClass("active");
    // $("#inteligencia").removeClass("active");
    // $("#comportamiento").addClass("active");
};

export function ShowPersonalidad() {
    // $("#comportamiento_body").css('display', 'none');
    // $("#personalidad_body").css('display', 'block');
    // $("#inteligencia_body").css('display', 'none');
    // $("#comportamiento").removeClass("active");
    // $("#inteligencia").removeClass("active");
    // $("#personalidad").addClass("active");
};

export function ShowInteligencia() {
    // $("#comportamiento_body").css('display', 'none');
    // $("#personalidad_body").css('display', 'none');
    // $("#inteligencia_body").css('display', 'block');
    // $("#comportamiento").removeClass("active");
    // $("#personalidad").removeClass("active");
    // $("#inteligencia").addClass("active");
};

import { Dialog,DialogContent, DialogTitle } from '@mui/material'
import * as React from 'react';

import {
  Box, Stepper, Step,
  StepLabel, Button, Typography
} from '@mui/material'

import { Puesto } from '../Password/Steps/puesto'
import { TypePwd } from '../Password/Steps/TypePwd'
import { Password } from '../Password/Steps/password'
import { Candidato } from '../Password/Steps/candidato'
import { Resume } from '../Password/Steps/resume'

function addYearToDate(date, years) {
  let newDate = new Date(date);
  newDate.setFullYear(newDate.getFullYear() + years);
  return newDate;
}

export function HelpModal(props) {

  let { visible, handleClose } = props
  const now = React.useRef(new Date());

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const [password, setPassword] = React.useState({
    puesto: [],
    pwd: "",
    deadpwd: addYearToDate(now.current, 1),
    dateToActived: now.current,
    sendmail: true,
    nombrepuesto: null,
    nombreEmpresa: null,
    candidatos : []
  });

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setPassword({
      puesto: [],
      pwd: "",
      deadpwd: now.current,
      dateToActived: now.current,
      sendmail: true,
      nombrepuesto: null,
      nombreEmpresa: null,
      candidatos : []
    })
    setActiveStep(0);
  };


  const steps = [
    {
      label : "Tipo de contraseña",
      operation : 
        <TypePwd
        handleNext={handleNext}
        handleBack={handleBack}
        password={password}
        setPassword={setPassword}
        />
    },
    {
      label: 'Puesto',
      operation:
      <Puesto
        handleNext={handleNext}
        handleBack={handleBack}
        password={password}
        setPassword={setPassword}
        />
    },
    {
      label: 'Contraseña',
      operation:
      <Password
        handleNext={handleNext}
        handleBack={handleBack}
        password={password}
        setPassword={setPassword}
        />
    },
    {
      label: 'Candidatos',
      operation:
      <Candidato
        handleNext={handleNext}
        handleBack={handleBack}
        password={password}
        setPassword={setPassword}
        />
    },
    {
      label: 'Confirmar',
      operation:
      <Resume
        handleBack={handleBack}
        password={password}
        handleClose={handleClose}
        handleReset={handleReset}
        />
    },
  ];


  return (
    <Dialog 
      open={visible}
      fullWidth
      maxWidth="md"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={handleClose}
    >
      <DialogTitle>
        <button type="button" className="close" onClick={handleClose}>&times;</button>
         Crear Contraseña
      </DialogTitle>
      <DialogContent className="modal-body">

        <Box sx={{ width: '100%' }}>
          <Stepper
            sx={{
              flexWrap: 'wrap'
            }}
            activeStep={activeStep}>
            {steps.map((step, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={step.label} {...stepProps}>
                  <StepLabel
                    sx={{ margin: 1 }}
                    {...labelProps}>{step.label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset}>Reiniciar</Button>
              </Box>
            </React.Fragment>
          ) : (
              <React.Fragment>

                <Box style={{ padding: 18, marginTop: 2 }}>
                  {steps[activeStep].operation}
                </Box>

              </React.Fragment>
            )}
        </Box>

      </DialogContent>
    </Dialog>
  )
}

import { useState } from 'react'
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
  Stack, TextField, Box, Button,
  Backdrop, CircularProgress,
} from '@mui/material';
import toast, { Toaster } from 'react-hot-toast';
import * as Yup from 'yup';

import { Service } from '../../Utils/HTTP';
// import useAuth from '../../Auth/useAuth';;

export function PersonalInfo(props) {

  let { handleBack, setClient, client } = props

  let navigate = useNavigate()

  const RegisterSchema = Yup.object().shape({
    nit: Yup.string().min(2, 'Demasiado corto!').max(50, 'Demasiado largo!').required('El nit es requerido'),
    cui: Yup.string().min(2, 'Demasiado corto!').max(50, 'Demasiado Largo!').required('Tu CUI/DPI es requerido'),
    direccion: Yup.string().min(2, 'Demasiado corto!').max(50, 'Demasiado Largo!').required('La direccion es requerida'),
    nacimiento: Yup.date().required('Tu fecha  nacimiento es requerida'),
    telefono: Yup.number('Ingresa únicamente números').required('Tu numero de telefono es requerido')
  });

  let [open, setOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      nit: client.nit,
      cui: client.cui,
      direccion: client.direccion,
      nacimiento: client.nacimiento,
      telefono: client.telefono
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      setOpen(true);

      let new_client = {
        ...client, ...values
      }
      setClient(new_client)

      let body = {
        "nombre": new_client.firstName,
        "apelidos": new_client.lastName,
        "telefono": new_client.telefono,
        "direccio": new_client.direccion,
        "fechacumple": new_client.nacimiento,
        "nit": new_client.nit,
        "cui": new_client.cui,
        "email": new_client.email,
        "username": new_client.email,
        "pwd": new_client.password,
        "empresa": new_client.empresa
      }

      let req = new Service('/registro');

      req
        .post(body)
        .then(data => {

          setTimeout(() => {
            setOpen(false);
            return navigate('/')
          }, 2000)

          console.log("DATA ::", data)
          let nombre = client.firstName + " " + client.lastName
          toast.success(`Bienvenido ${nombre} !!`)


        })
        .catch(error => {
          console.log("ERROR ::", error)
          toast.error(`Ups ocurrió un error puede que tu usuario yo exista o sea un error interno, intenta nuevamente más tarde o ponte en contacto con nosotros`)
          return setOpen(false);
        })

    }
  });


  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider style={{ padding: 15 }} value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              label="Numero de Nit"
              fullWidth
              {...getFieldProps('nit')}
              error={Boolean(touched.nit && errors.nit)}
              helperText={touched.nit && errors.nit}
            />

            <TextField
              label="CUI/DPI"
              fullWidth
              {...getFieldProps('cui')}
              error={Boolean(touched.cui && errors.cui)}
              helperText={touched.cui && errors.cui}
            />
          </Stack>

          <TextField
            fullWidth
            type="text"
            label="Dirección"
            {...getFieldProps('direccion')}
            error={Boolean(touched.direccion && errors.direccion)}
            helperText={touched.direccion && errors.direccion}
          />

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              type="date"
              label="Fecha de nacimiento"
              fullWidth
              InputLabelProps={{ required: true }}
              {...getFieldProps('nacimiento')}
              error={Boolean(touched.nacimiento && errors.nacimiento)}
              helperText={touched.nacimiento && errors.nacimiento}
            />

            <TextField
              label="Telefono"
              fullWidth
              {...getFieldProps('telefono')}
              error={Boolean(touched.telefono && errors.telefono)}
              helperText={touched.telefono && errors.telefono}
            />
          </Stack>

          <Box sx={{ mb: 2 }}>
            <div style={{ paddingTop: 15 }}>
              <Button
                name="save_new_client"
                type="submit"
                className="registerBtn"
                variant="contained"
                // onClick={handleNext}
                sx={{ mt: 1, mr: 1 }}
              >
                {'Registrarme'}
              </Button>
              <Button
                onClick={handleBack}
                sx={{ mt: 1, mr: 1 }}
              >
                Regresar
              </Button>
            </div>
          </Box>

        </Stack>

      </Form>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <Backdrop
        sx={{ color: '#fd4b4b', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={() => false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </FormikProvider>
  )
}

import { Box, Paper, } from '@mui/material'

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const SUCCESS = () => {
  return(
    <>
    <CheckCircleIcon
        style={{
          color :'green',
          fontSize:90
        }}
      />
    </>
  )
}

export function Completed() {
  return (
    <div className="content-section">
      <div className="main">
        <Box >
          <Paper style={{padding:50 }}>
            <h1>Tus respuestas han sido enviadas <SUCCESS/></h1>
          </Paper>
        </Box>
      </div>
    </div>
  )
}

import React, { useState, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap'
import { Toaster } from 'react-hot-toast';

import {
  ToggleButton, ToggleButtonGroup, Box,
  Paper, Pagination, Chip, TextField, InputAdornment,
} from '@mui/material';

import {
  ViewList as ViewListIcon, ViewModule as ViewModuleIcon,
  Search as SearchIcon
} from '@mui/icons-material';

import { Service } from '../Utils/HTTP';
import { Divide } from '../Utils/Paginate';

import Express from '../Components/Modal/AgregarExpress';
import Manual from '../Components/Modal/AgregarManual';

import Editar from '../Components/Modal/EditPlaza';
import Eliminar from '../Components/Modal/EliminarPlaza';
import Mostrar from '../Components/Modal/MostrarPlaza';

import { ListMode } from '../Components/Puestos/ListMode'
import { GridMode } from '../Components/Puestos/GridMode'
import { Add as AddIcon, } from '@mui/icons-material/'

import { Centred as Loading } from '../Components/Generics/loading'
import { useSelector } from 'react-redux';

import { useQuery } from 'react-query';

export function Puestos() {

  const auth = useSelector((state) => state.token)
  const [page, setPage] = useState(1);

  const getAll = async () => {
    let rest = new Service("/plaza/getall")
    let response = await rest.getQuery(auth.token);
    return response;
  }

  const { isLoading, error, data: result } = useQuery('puestos', getAll,{
    retry:false,
    cacheTime: 0,
  });

  const changePage = useCallback((_, value) => setPage(value), []);
  const [alignment, setAlignment] = React.useState('grid');
  const handleChange = useCallback((_event, newAlignment) => setAlignment(newAlignment), [])

  const children = [
    <ToggleButton value="list" key="list">
      <ViewListIcon />
    </ToggleButton>,
    <ToggleButton value="grid" key="grid">
      <ViewModuleIcon />
    </ToggleButton>,
  ];

  const control = {
    value: alignment,
    onChange: handleChange,
    exclusive: true,
  };

  let [manual, setManual] = React.useState(false);
  let [expres, setExpress] = React.useState(false);

  let [puesto, setPuesto] = React.useState(false);
  let [edit, setEdit] = React.useState(null);
  let [del, setDelete] = React.useState(null);
  let [show, setShow] = React.useState(null);
  let [filter, setFilter] = React.useState(null);

  const toggle = useCallback((type, puesto) => {
    setPuesto(puesto)
    switch (type) {
      case "VER": {
        setShow(!show);
        break;
      }
      case "EDIT": {
        setEdit(puesto.id)
        break;
      }
      case "DEL": {
        setDelete(!del)
        break;
      }
      default: break;
    }
  }, [del, show])

  const total_items = Divide(filter ? filter : ( result ? result.data: []  )).length

  return (
    <div className="content-section">
      <div className="main">
        <Box>
          <Paper sx={{ mb: 2, padding: 2, }}>
            <Row style={{ alignItems: 'center', padding: 10, justifyContent: 'space-between' }}>
              <Col md="4" sm="4" xs="4">
                <div className="breadcrumb-header">
                  <Box sx={{ float: 'left', display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
                    <ToggleButtonGroup style={{ marginRight: 20 }} size="small" {...control}>
                      {children}
                    </ToggleButtonGroup>
                  </Box>
                </div>
              </Col>
              <Col md="8" sm='8' xs="4">
                <div className="add_producto">
                  <div onClick={() => setManual(true)} className="btn_add_producto">
                    <span className="btn_plaza_common" >Agregar manual <AddIcon fontSize="small" /></span>
                  </div>
                </div>
                <div onClick={() => setExpress(true)} className="add_producto">
                  <div className="btn_add_producto">
                    <span className="btn_plaza_common">Agregar express <AddIcon fontSize="small" /></span>
                  </div>
                </div>
              </Col>
            </Row>
            <div style={{ padding: 7 }}>
              <div className={` main_grid_plazas main_productos ${alignment === 'grid' ? 'activar_vista' : 'desactivar_vista'}`} id="grid_view">
                <Row style={{ minHeight: '57vh' }}>
                  {isLoading && <Loading />}
                  { !error ?
                    <GridMode
                      new_puesto={() => setManual(true) }
                      toggle={toggle}
                      showing={alignment}
                      data={filter ? Divide(filter) : Divide(result ? result.data  : [])}
                      index={page - 1}
                    /> : undefined
                  }
                  {!result && !isLoading && <center><h1>No tienes ningún puesto</h1></center>}
                </Row>
              </div>
              <div className={`main_list_products ${alignment === 'list' ? 'activar_vista' : 'desactivar_vista'}`} id="list_view_products">
                <Row style={{ minHeight: '57vh' }}>
                  {isLoading && <Loading />}
                  { !error ?
                    <ListMode
                      new_puesto={() => setManual(true) }
                      toggle={toggle}
                      showing={alignment}
                      data={filter ? Divide(filter) : Divide(result ? result.data : [])}
                      index={page - 1}
                    /> : undefined
                  }
                  {!result && !isLoading && <center><h1>No tienes nigún puesto</h1></center>}
                </Row>
              </div>

              <Row className="mamo">
                <Col id="pagi_bottom" >

                  <Pagination
                    sx={{
                      "& ul": {
                        paddingTop: "20px",
                        justifyContent: "center"
                      }
                    }}
                    siblingCount={2}
                    boundaryCount={2}
                    shape='rounded'
                    count={total_items}
                    page={page}
                    onChange={changePage}
                  />
                  <div className="chip_and_search">

                    <TextField
                      id="search_plaza_input"
                      onChange={(event) => {
                        let nombre = event.target.value
                        if (nombre) {
                          let temp = result.data.filter(plaza => {
                            let isset = plaza.nombrepuesto.toUpperCase().includes(nombre.toUpperCase())
                            return isset
                          })
                          console.log(temp)
                          setFilter(temp)
                        } else {
                          setFilter(null)
                        }
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />

                    <Chip style={{ marginLeft: 10, margin: 5 }} label={"Total: " + (filter ? filter.length : (result ? result.data.length : 0))} />
                  </div>
                </Col>
              </Row>

            </div>
          </Paper>
        </Box>
      </div>

      <Express setExpress={setExpress} visible={expres} onClose={() => setExpress(false)} />
      <Manual visible={manual} onClose={() => setManual(false)} />

      <Mostrar puesto={puesto} visible={show} toggle={toggle} />
      <Editar visible={edit} toggle={toggle} />
      <Eliminar visible={del} onClose={() => setDelete(false)} />

      <Toaster position="top-right" />

    </div>
  )
}

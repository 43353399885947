import { useState, useEffect } from 'react';
import { Typography, Box, Paper, Divider } from '@mui/material';

import { Service } from '../Utils/HTTP.js';
import { TestCard } from '../Components/HomeUser/TestCard';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'

export function HomeUser(){

  const [tests, setTests] = useState([]);
  const [password, setPasword] = useState({});
  const auth = useSelector( state => state.token.candi);
  const profile = useSelector( state => state.user.profile);

  useEffect(() => {
    let { email , password} = profile
    let rest = new Service(`/plaza/contrasenia/${password}/${email}`);
    rest
      .get(auth.token)
      .then(({ data }) => {
        console.log('plaza contrasenia: ', data)
        setTests(data.tests)
        delete data.tests
        setPasword(data);
      })
      .catch(erro => {
        console.error("ERR : ", erro)
      })

  },[auth, profile])

  return(
    <div className="content-section">
      <div className="main">
        <Box sx={{ width: '100%' }}>
          <Paper elevation={2} sx={{ mb: 2, padding: 2, height: '100%', minHeight: '95vh'}}>
            <h2>
              Bienvenido al sistemas de pruebas psicometricas.
            </h2>
            <h3>Instrucciones Generales</h3>
            <Typography>

              Bienvenido, el sistema tiene como objetivo evaluar diferentes características como medir y cuantificar los procesos cognoscitivos de la mente humana. Parar resolver las pruebas que están asignadas te recomendamos busques un lugar apropiado, ya que necesitaras en promedio 2 horas libre y es importante que se tenga el 100% de tu concentración.
            </Typography>
            <Divider style={{margin : 5}}/>
            <h5>Pruebas para {password.nombrepuesto}</h5>
            <div className="test_list" style={{ marginTop :15 }}> 
              {
              tests.map( test => {
                return (
                <Link key={test.id} to={`/user/prueba/${test.id}`}>
                  <TestCard test={test} />
                </Link>
                )
              })
            }
            </div>
          </Paper>
        </Box>
      </div>
    </div>
  )

}

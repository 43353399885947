import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  Box, Link, Container, Typography, Card,
  StepLabel, Step, Stepper, Button, Paper, StepContent
} from '@mui/material';
// import jwt_decode from "jwt-decode";

import { RegisterForm } from '../Components/Register/RegisterForm';
import { PersonalInfo } from '../Components/Register/PersonalInfo';
import { Company  } from '../Components/Register/Company';
import AuthLayout from '../Components/Register/AuthLayout';

import Mock from '../Images/register_mok.png'
import { useNavigate } from 'react-router-dom'
import { Col, Row, } from 'react-bootstrap';
import { useSelector } from 'react-redux'


export function Register() {

  let navigate = useNavigate()
  const auth = useSelector((state) => state.token)

  React.useEffect(() => {
    if (auth.token) {
      // let body_token = jwt_decode(auth.token);
      // if (body_token.authorities.includes("Reclutador")) {
      return navigate('/dashboard/home')
      // }
    }
  }, [auth, navigate])


  const TODAY = new Date()

  const [activeStep, setActiveStep] = React.useState(0);
  const [client, setClient] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    password_confirm: '',
    nit: "",
    cui: "",
    direccion: "",
    nacimiento: TODAY.getFullYear() + '-10-18',
    telefono: "",
    empresa: {
      nombrecpmercial:'',
      telefono: '',
      decription: '',
    }
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const steps = [
    {
      label: 'Información de tu cuenta',
      description:
        <RegisterForm
          client={client}
          setClient={setClient}
          handleBack={handleBack}
          handleNext={handleNext}
        />
    },
    {
      label: 'Empresa',
      description: 
      <Company 
          client={client}
          setClient={setClient}
          handleBack={handleBack}
          handleNext={handleNext}
      />
    },
    {
      label: 'Datos Personales',
      description:
        <PersonalInfo
          client={client}
          setClient={setClient}
          handleBack={handleBack}
        />
    },
  ];

  return (
    <Container style={{ paddingTop: 40 }}>
      <Row className="register_content">
        <Col md={4} className="d-none d-md-block">

          <Card className="card_register">

            <AuthLayout>

              <Link to="/login" component={RouterLink}>

              </Link>
            </AuthLayout>

            <h1 variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Efectividad para tus procesos de reclutamiento
            </h1>
            <img alt="register" src={Mock} />
          </Card>

        </Col>

        <Col style={{ "padding": 45 }} md={6}>

          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Empieza de forma gratuita.
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Gratis para siempre. No se necesita tarjeta de crédito.
            </Typography>
          </Box>

          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel
                  optional={
                    index === 2 ? (
                      <Typography variant="caption">Last step</Typography>
                    ) : null
                  }
                >
                  {step.label}
                </StepLabel>
                <StepContent style={{ padding: 25 }}>
                  {step.description}
                </StepContent>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length && (
            <Paper square elevation={0} sx={{ p: 3 }}>
              <Typography>All steps completed - you&apos;re finished</Typography>
              <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                Reset
              </Button>
            </Paper>
          )}

          <div>
            <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 0 }}>
              Estoy de acuerdo con las {" "}
              <Link underline="always" sx={{ color: "#d32f2f" }}>
                Condiciones de servicio
              </Link>
              {" "}y{" "}
              <Link underline="always" sx={{ color: "#d32f2f" }}>
                Política de privacidad
              </Link>
              .
            </Typography>

            <Typography variant="subtitle2" sx={{ mt: 0, textAlign: 'center' }}>
              Ya tiene una cuenta?&nbsp;
              <Link to="/login" component={RouterLink}>
                Ingresa
              </Link>
            </Typography>
          </div>


        </Col>
      </Row>
    </Container>
  );
}

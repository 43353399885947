import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { } from '@mui/material'
// import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { Service } from './Utils/HTTP';

import {
  IconButton, Tooltip,
} from '@mui/material';

import {
  CheckBox as CheckBoxIcon,
  AddTask as AddTaskIcon,
  // NewReleases as NewReleasesIcon,
  Error as ErrorIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon
} from '@mui/icons-material'



function IconStatus(props) {

  let { pwd, setId } = props
  // let navigate = useNavigate()
  // console.log('MPROP',props)
  let auth = useSelector((state) => state.token)

  const calificar = () => {
    let rest = new Service(`/prueba/calificacion/cleaver/report/${props.pwd}`);

    rest
      .getQuery(auth.token)
      .then(r => {
        console.log("SENDING ID:", pwd)
        setId(pwd)()
        // console.log('RESULTADO:',r)
        // navigate('/dashboard/resultados/' + props.pwd)
      })
      .catch(e => {
        // navigate('/dashboard/resultados/' + props.pwd)
      })
  }


  return (
    <Tooltip title={props.message}>
      <IconButton
        onClick={() => {
          if (parseInt(props.estado) === 100) {
            calificar()
            // navigate('/dashboard/resultados/' + props.pwd)
          }
        }}
        style={{ color: props.color }}
        components="label">
        {<props.icon />}
      </IconButton>
    </Tooltip>

  )
}



function getOperation(estado, SuperPWD, setId) {
  // Patrik: 100 -> Significa que el examen esta completo
  // Patrik: 99 -> Que el candidato lo esta haciendo (como "doing test" del otro servicio)
  // Patrik: 97 ->  Que hay un error y que hay mas respuestas de las que deberian
  // Patrik: 1 -> Solo esta asignado.
  switch (estado) {
    case 1: // solo asignado
      return <IconStatus color="#0bd641" icon={AddTaskIcon} message="Candidato Asignado" />
    case 97: //error en las respuestas
      return <IconStatus color="var(--main)" icon={ErrorIcon} message="Hay error en las respuestas" />
    case 99: // el candidato se encuentra realizndolo
      return <IconStatus color="#f5f511" icon={AssignmentTurnedInIcon} message="El candidato se encuentra realizando la prueba" />
    case 100: // finalizado
      return <IconStatus setId={setId} color="#0bd641" estado={100} icon={CheckBoxIcon} message="Calificar el examen" pwd={SuperPWD} />
    default: return null
  }
}


function Asignaciones(props) {

  let { asign, setId } = props;
  let { estado, cattest, id: SuperPWD } = asign;

  return (
    <div>
      {cattest.decription}
      {/*
          test_result.length > 0 ?
            (
              <Tooltip title="Mostrar resultados">
                <IconButton
                  onClick={() => navigate('/dashboard/resultados/' + SuperPWD)}
                  style={{ color: '#0bd641' }}
                  aria-label="puesto_status"
                  components="label">
                  <CheckBoxIcon />
                </IconButton>
              </Tooltip>
            ) : null
      */}
      {
        getOperation(estado, SuperPWD, setId)
      }
    </div>
  )
}




export function DenseTable(props) {
  let { users, setId } = props
  // console.log("users", users)
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left"><strong>Nombre</strong></TableCell>
            <TableCell align="right"><strong>Asignacion</strong></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left" component="th" scope="row">
                {row.candi.nombre + " " + row.candi.apellidos}
              </TableCell>
              <TableCell align="right">
                {row.asignaciones.map(a => <Asignaciones setId={setId} key={a.id} asign={a} />)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

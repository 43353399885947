import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'

export function Configuracion() {

  let recluter = useSelector((state) => state.recluter.info)
  console.log('recluter: ', recluter)

  return (
    <div className="content-section">
      <div className="main">
        <h1 className="breadcrumb-header">Configuraciones</h1>
        <Row>
          <Col md="12">
            <div className="panel">
              <Row style={{padding:15}} className="row">
                <Col md="6">
                  <div className="inputs_config">
                    <p>Datos personales</p>
                    <label>Nombre</label>
                    <input type="text" name="nombre_user" placeholder="Fernando Vásquez" />
                    <label>Apellidos</label>
                    <input type="text" name="puesto" placeholder="Gerente general" />
                    <label>Dirección</label>
                    <input type="text" name="direccion" placeholder="Melbourn, Australia" />
                    <label>Número</label>
                    <input type="number" name="numero" placeholder="12345678" />
                  </div>
                </Col>
                <Col md="6">
                  <div className="inputs_config">
                    <p>Redes sociales</p>
                    <label>Facebook</label>
                    <input type="text" name="facebook" placeholder="Link de Facebook" />
                    <label>Linkein</label>
                    <input type="text" name="linkedin" placeholder="Link de Linkedin" />
                    <label>Instagram</label>
                    <input type="text" name="instagram" placeholder="Link de Instagram" />
                    <label>Correo</label>
                    <input type="email" name="instagram" placeholder="Correo electrónico" />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md='12'>
                  <div className="boton_guardar_info">
                    <div className="success_btn">
                      <span >Guardar</span>
                    </div>
                  </div>
                </Col>
              </Row>

            </div>
          </Col>
        </Row>
      </div>
    </div>
  )

}

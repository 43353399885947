import { createSlice } from '@reduxjs/toolkit'

const initialState = { info : {} }

export const recluterSlice = createSlice({
  name : 'recluter',
  reducers: {
    setInfo : (state,action) => {
      state.info = action.payload;
    }
  },
  initialState
})

export const { setInfo } = recluterSlice.actions;
export default recluterSlice.reducer;

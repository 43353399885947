import React from "react";
import { Grow } from '@mui/material';
import { Col } from 'react-bootstrap'
import { PuestoCard } from './Card';
import {SinPuestos} from './SinPuestos'

export function GridMode(props) {

  let { data, index, showing, toggle, new_puesto } = props;
  
  console.log(data)

  return (
    <React.Fragment>
      {
        data.length && showing === 'grid' ?
          data[index].map(plaza => {
            return (
              <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={500} key={plaza.id} >
                <Col lg="4" md="6" sm="6" xs="12" >
                  <div style={{ padding: 5 }}>
                    <PuestoCard toggle={toggle} plaza={plaza} />
                  </div>
                </Col>
              </Grow>
            )
          }) : <SinPuestos toggle={new_puesto}/>
      }
    </React.Fragment>
  )
}

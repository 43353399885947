import * as React from 'react';
import {
  Button, Dialog, DialogActions, DialogContent,
  FormControlLabel, Checkbox, Stack,
  TextField, CircularProgress, Divider, Typography
} from '@mui/material'

import { AddCircle } from '@mui/icons-material/';
import { MailTable } from './Steps/MailTable';

import { Col, Row } from 'react-bootstrap'

import toast, { Toaster } from 'react-hot-toast';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from 'react-query'
import { useForm, Controller } from "react-hook-form";
import { Service } from '../../Utils/HTTP.js'
import { useSelector } from 'react-redux'

import { useFormik, Form, FormikProvider } from 'formik';
import { AdapterDateFns as DateFnsUtils } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


function Candidatos(props) {

  const CandidatoSchema = Yup.object().shape({
    nombres:
      Yup.string()
        .min(2, 'Demasiado corto!')
        .max(50, 'Demasiado largo!')
        .required('Ingresa un nombre válido'),
    apellidos:
      Yup.string()
        .min(2, 'Demasiado corto!')
        .max(50, 'Demasiado Largo!')
        .required('Ingresa un apellido válido'),
    mail:
      Yup.string()
        .required('Ingresa un email válido')
        .email("Correo no valido")
  });


  let { candidatos, add, remove, home } = props

  const formik = useFormik({
    initialValues: {
      nombres: "",
      apellidos: "",
      mail: "",
    },
    onSubmit: () => {

      if (!values.nombres || !values.apellidos || !values.mail) {
        return toast.error("Completa la informacion del candidato")
      }

      if (!isValid) {
        return toast.error("Completa la informacion del candidato")
      }

      let user = {
        'id': -1,
        'nombres': values.nombres,
        'apellidos': values.apellidos,
        'mail': values.mail,
      }

      add(user)
      resetForm();

    },
    validationSchema: CandidatoSchema,
  });

  var { errors, touched, handleSubmit, getFieldProps, values, resetForm, isValid } = formik;

  return (
    <FormikProvider style={{ padding: 25 }} value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>

          {
            !home ?
              (
                <>

                  <Typography style={{ padding: 5, marginBottom: !home ? 15 : 0 }}>
                    {home ? "" : "Ingresa la informacion del candidato"}
                  </Typography>
                  <Divider />


                  <Stack
                    style={{ paddingTop: 15, paddingBottom: !home ? 15 : 0 }}
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={2}>

                    <TextField
                      style={{ display: 'block', background: '' }}
                      label="Nombre"
                      {...getFieldProps('nombres')}
                      error={Boolean(touched.nombres && errors.nombres)}
                      helperText={touched.nombres && errors.nombres}
                    />

                    <TextField
                      style={{ display: 'block' }}
                      label="Apellidos"
                      {...getFieldProps('apellidos')}
                      error={Boolean(touched.apellidos && errors.apellidos)}
                      helperText={touched.apellidos && errors.apellidos}
                    />
                  </Stack>

                  <Stack direction={{ xs: 'column', sm: 'row' }} >
                    <TextField
                      style={{ display: 'block' }}
                      fullWidth
                      type="email"
                      label="Correo Electronico"
                      {...getFieldProps('mail')}
                      error={Boolean(touched.mail && errors.mail)}
                      helperText={touched.mail && errors.mail}
                    />

                    <Button type="submit">
                      <AddCircle style={{ color: 'var(--main)' }} />
                    </Button>

                  </Stack>
                </>
              ) : undefined
          }

          <MailTable
            home={home}
            remove={remove}
            users={candidatos}
          />

        </Stack>
      </Form>
    </FormikProvider>
  );
}

export function ModalEdit(props) {

  const auth = useSelector((state) => state.token)
  let { password, open, handleOpen, home } = props
  let { pwd, plz } = password

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={open}
      onClose={() => handleOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <ModalForm
          home={home}
          pwdinfo={{ pwd, plz }}
          closeModal={handleOpen}
          token={auth.token}
        />
      </DialogContent>
    </Dialog>
  )
}

export function Loading() {
  return (
    <CircularProgress style={{ color: 'var(--main)' }} />
  )
}

function ModalForm(props) {

  let { pwdinfo, closeModal, home } = props
  const auth = useSelector((state) => state.token)
  let [candidatos, setCandidatos] = React.useState([]);
  let [password, setPassword] = React.useState();
  const queryClient = useQueryClient()

  const pwdSchema = Yup.object().shape({
    pwd: Yup.string(),
    deadpwd: Yup.date('Escoge una fecha valida').required("Escoge una fecha valida"),
    state: Yup.boolean(),
    dateToActived: Yup.date('Escoge una fecha valida').required("Escoge una fecha valida"),
  })

  const { reset, control, register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(pwdSchema),
    defaultValues: {
      pwd: 0,
      deadpwd: 0,
      state: false,
      dateToActived: 0,
    }
  });

  React.useEffect(() => {
    let { pwd, plz } = pwdinfo;
    let rest = new Service(`/contrasenia/${home ? pwd : btoa(pwd)}/${plz}`)

    rest.getQuery(auth.token)
      .then(resp => {

        let json_data = resp;
        let mapCandi = resp.data.candidatospwds.map(pwd => {
          let { apellidos, nombre, mail, id } = pwd.candi
          return { nombres: nombre, apellidos, mail, id }
        })

        json_data.data['candidatospwds'] = mapCandi;
        let password = json_data.data
        setPassword(password)
        setCandidatos(password.candidatospwds)

        reset({
          pwd: password.pwd,
          deadpwd: password.deadpwd,
          state: parseInt(password.state) === 1,
          dateToActived: password.dateToActived,
        })

      })
      .catch(error => console.log(error))

  }, [auth.token, pwdinfo, reset, home])

  const saveCandidato = async (body) => {
    let rest = new Service('/passwordcandidato/candidato')
    return await rest.postQuery(body, auth.token)
  }

  function onSubmit(fields) {

    let candi_body = candidatos.map(c => {
      return {
        ...c,
        'nombrepuesto': 'test',
        'nombreEmpresa': 'dit',
        "idContrasenia": password.id,
        "sendmail": 1,
      }
    })

    let rest = new Service('/contrasenia/create');
    let { deadpwd, dateToActived, pwd, state } = fields

    fields['pwd'] = pwd;
    fields['deadpwd'] = new Date(deadpwd).toISOString();
    fields['dateToActived'] = new Date(dateToActived).toISOString();
    fields['link'] = 'www.psicoadmin.ditaca.org'
    fields['state'] = state ? 1 : 0
    delete password['candidato_id'];
    delete password['tokensecurity'];
    delete password['candidatospwds'];

    let body_req = {
      ...password, ...fields
    }

    rest.putQuery(body_req, auth.token)
      .then(async _result => {

        queryClient.invalidateQueries('passwords')
        await saveCandidato(candi_body)

        setTimeout(() => {
          closeModal(false)
        }, 1000)
        toast.success("Contraseña Actualizada")
      })
      .catch(bad => {
        console.log('ERROR', bad)
        toast.error("Ocurrio un error")
      })
  }


  function removeCandidato(umail) {
    let without = candidatos.filter(user => user.mail !== umail)
    setCandidatos(without)
  }

  function addCandidato(candidato) {
    let temp = [...candidatos, candidato]
    setCandidatos(temp)
  }

  return (
    <Row>
      <Col >
        <form style={{ padding: !home ? 20 : 0, maxWidth: 950 }} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={4}>

            <TextField
              {...register('pwd')}
              variant="filled"
              type="text"
              label="Contraseña Cifrada"
              InputProps={{
                readOnly: home
              }}
            />

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                label="Nombre de la Contraseña"
                fullWidth
                variant="filled"
                value={password ? atob(password.pwd) : ""}
                type="text"
                InputProps={{
                  readOnly: home
                }}
              />

              {
                !home ?
                  <FormControlLabel
                    label="Activo?"
                    control={
                      <Controller
                        name="state"
                        control={control}
                        render={({ field: props }) =>
                          <Checkbox
                            style={{ color: 'var(--main)' }}
                            checked={props.value}
                            onChange={(e) => props.onChange(e.target.checked)}
                          />
                        }
                      />
                    }
                  /> : null
              }


            </Stack>

            <LocalizationProvider dateAdapter={DateFnsUtils}>
              <Controller
                name="dateToActived"
                control={control}
                render={({ field }) =>
                  <DesktopDatePicker
                    disabled={home}
                    {...field}
                    label="Fecha de Activación"
                    inputFormat="dd/MM/yyyy"
                    fullWidth
                    error={Boolean(errors?.dateToActived)}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        helperText={errors?.dateToActived?.message}
                      />}
                  />
                }
              >
              </Controller>
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={DateFnsUtils}>
              <Controller
                name="deadpwd"
                control={control}
                render={({ field }) =>
                  <DesktopDatePicker
                    {...field}
                    disabled={home}
                    label="Fecha de Vencimiento"
                    error={Boolean(errors?.deadpwd)}
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        helperText={errors?.deadpwd?.message}
                        label="Fecha de Vencimiento"
                      />}
                  />
                }
              >
              </Controller>
            </LocalizationProvider>

            {!home ?
              <DialogActions style={{ paddingTop: 25, 'justifyContent': "flex-start" }}>
                <Button onClick={() => closeModal(false)}>
                  Cerrar
                </Button>
                <Button type="submit" style={{ color: 'white', background: 'var(--main)' }} >
                  Guardar
                </Button>
              </DialogActions> :

              <DialogActions
                style={{
                  justifyContent: "flex-start",
                }} >
                <Button onClick={() => {
                  console.log('close modal')
                  console.log(closeModal)
                  closeModal(true)
                }}>
                  Cerrar
                </Button>
              </DialogActions>

            }

          </Stack>
        </form>
        <Toaster position="bottom-right" />
      </Col>
      <Col >
        <Candidatos
          home={home}
          add={addCandidato}
          remove={removeCandidato}
          candidatos={candidatos}
        />
      </Col>
    </Row>
  )
}


import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import rootReducer from '../src/Reducers/';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { loadState, saveState } from './Utils/state';
// import { legacy_createStore as createStore } from '@reduxjs/toolkit';
// const store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

let store = configureStore({
  reducer : rootReducer,
  preloadedState: loadState()
})

store.subscribe( function () {
  saveState(store.getState())
})

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App/>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);


import { Link } from 'react-router-dom'
import { CircularProgress, } from '@mui/material'
import { useEffect, useState } from 'react'

export function Card( props ){

    let [loading, setLoading] = useState(true);

    useEffect(() => {

        let time = setTimeout(() =>{
            setLoading(false)
        },3000)

        return () =>  {
            clearTimeout(time);
        }

    },[loading])


    return(
        <div className="panel" >
            <Link to={props.to}>
                <div className="panel_card">
                    <div>
                        <h1>{ props.title }</h1>
                    </div>
                    <div>
                        <props.icon style={{ color :'#fd4b4b', fontSize : 45, margin : 5}}/>
                    </div>
                    <div className="content_info">
                        {
                            loading ?
                            <p style={{ paddingTop : 10}}>
                                <CircularProgress color="inherit" />
                            </p>
                            : <p>{ props.quantity }</p>
                        }
                    </div>
                </div>
            </Link>
        </div>
    )
}

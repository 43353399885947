import React from 'react';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import { Modal } from 'react-bootstrap'

const ExpressSchema = Yup.object().shape({
    link : Yup.string().required('El enlace es requerido').url("Debes agregar un enlace válido, recurda iniciar con http o https ").min(5).max(190),
})

function Express (props) {

    let { visible, onClose, success } = props

    return (
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered  show={visible} onHide={onClose}>
            <Modal.Header>
                <button onClick={onClose} type="button" className="close" data-dismiss="modal">&times;</button>
                <h4 className="modal-title">Agregar plaza express</h4>
            </Modal.Header>
            <Modal.Body>

                <Formik
                    initialValues={{
                        link : ''
                    }}
                    validationSchema={ExpressSchema}
                    onSubmit={ (values) => {
                        success();
                        props.setExpress(false)
                        console.log('VALUES Express >> ',values)
                    }} 
                >
                    { ({ errors, touched, validateField, validateForm, setFieldValue   }) =>  (

                        <Form>
                            <div className="data_product">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Field type="link" name="link" placeholder="Link de la plaza"/>
                                        {errors.link && touched.link && <div className="error_feedback">{errors.link}</div>}
                                    </div>
                                    <div className="add_producto_confirm">
                                        <button className="btn_add_producto_confirm" type="submit">Agregar plaza</button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>

            </Modal.Body>
        </Modal>
    )
}

export default React.memo(Express)

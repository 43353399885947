import { createSlice } from '@reduxjs/toolkit';

const initialState = { profile : null, role :null }

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    setRole : (state, action ) => {
      state.role = Object.assign({}, state.role, action.payload);
    },
    getTestID : (state) => state.role.current_test_id
  }
})

export const { setProfile, setRole, getTestID } = userSlice.actions;
export default userSlice.reducer;

import * as React from 'react';
import toast, { Toaster } from 'react-hot-toast';
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from 'react-redux';
import { setToken } from '../Slices/tokenSlice'
import { setRole, setProfile } from '../Slices/userSlice'
import { setInfo } from '../Slices/recluterSlice'

import {
  Paper, Box, Grid, Checkbox, FormControlLabel, Typography,
  TextField, CssBaseline, Button, Avatar,
  Backdrop, CircularProgress,
} from '@mui/material'

import { createTheme, ThemeProvider } from '@mui/material/styles';

import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom'
import { Copyright } from '../Components/Footer.js'
import { Link } from 'react-router-dom'

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Service } from '../Utils/HTTP.js'

const LoginSchema = Yup.object().shape({
  email: Yup
    .string()
    .email('El correo debe ser válido')
    .required('El correo o usuario es requerido'),
  password: Yup
    .string()
    .required('El campo contraseña es requerido')
    .min(5, 'La contraseña debe contener mínimo 5 caracteres')
})

const theme = createTheme();

export function Login() {

  const navigate = useNavigate()
  const auth = useSelector((state) => state.token)
  const dispatch = useDispatch();


  const [open, setOpen] = React.useState(false);
  const handleClose = () => false

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {

      let { email, password } = values
      setOpen(true)
      let request = new Service(`/user?user=${email}&password=${password}&typeusr=isreclutador`)
      request
        .post({})
        .then(response => {

          dispatch(setInfo(response))

          let { token, nombre, apelidos, empresa } = response;
          toast.success(`Bienvenido ${nombre} ${apelidos} !!`)
          token = token.replace("Bearer ", "")
          console.log("TOKEN: ", token)
          window.token = token;
          let body_token = jwt_decode(token);
          let timestamp = body_token.exp * 1000;
          let restante = timestamp - Date.now();

          setTimeout(() => alert("Token Expirado") , restante )

          console.log({empresa,body_token})

          dispatch(setProfile(empresa))
          dispatch(setRole(body_token))

          setTimeout(() => {
            setOpen(false)
            dispatch(setToken(token))
            // auth.login(token)
          }, 2000)

        })
        .catch(_err => {
          setOpen(false)
          toast.error("Ups! usuario o contraseña incorrectos")
        })

    },
  });
  
  React.useEffect(() => {
    console.log('auth', auth)
    if(auth.token){
      // let body_token = jwt_decode(auth.token);
      // console.log(,body_token)
      // if(body_token.authorities.includes("Reclutador")){
        navigate('/dashboard/home')
      // }
    }
  }, [auth, navigate])

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://images.squarespace-cdn.com/content/v1/5c0ec3e30dbda3392d9c9d2d/1548101170354-A2I9LZIEE5CLJ1V5ZGAG/Psychometrictesting.jpg?format=2500w)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) => t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>

          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: '25%'
            }}>
            <Avatar sx={{ m: 1, bgcolor: '#fd4b4b' }}>
              <PersonIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Ingresar
            </Typography>

            <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
              <TextField
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                margin="normal"
                required
                fullWidth
                id="email"
                name="email"
                label="Correo Electrónico"
                autoComplete="email"
                autoFocus
              />
              <TextField
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                margin="normal"
                required
                fullWidth
                label="Contraseña"
                name="password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" sx={{
                  color: '#fd4b4b',
                  '&.Mui-checked': {
                    color: '#fd4b4b'
                  },
                }} />}
                label="Recordarme"
              />
              <Button
                id="login_btn_fn"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, bgcolor: 'var(--main)' }}
              >
                Ingresar
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link className="login_link" to='/password/recuperar'>
                    ¿Olvidaste tu contraseña?
                  </Link>
                </Grid>
                <Grid item>
                  <Link className="login_link" to='/register'>
                    {"¿No tienes cuenta? Regístrate"}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Toaster
        position="top-left"
        reverseOrder={false}
      />
      <Backdrop
        sx={{ color: '#fd4b4b', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

    </ThemeProvider>

  );
}

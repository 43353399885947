import React, {useEffect}  from 'react'
import { Col, Row, Container } from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';

import Logo from '../Images/logo.png'

import HeaderPruebas from '../Components/HeaderPruebas'
import ImagenPrueba from '../Images/prueba.png'

import Respuesta1 from '../Images/respuesta.png'
import Respuesta2 from '../Images/respuesta2.png'
import Respuesta3 from '../Images/respuesta3.png'


const PruebaScheme = Yup.object().shape({
    tipo_prueba : Yup.number().positive('Ingresa un valor valido').required('Selecciona una opcion'),
    duracion : Yup.number().positive('Ingresa una duracion valida').required('Ingresa una duracion valida'),
    nombre : Yup.string().required('El nombre es requerido').min(5).max(190)
})

export function PruebaNueva() {

    useEffect(() => {
        // $('#sites input:radio').addClass('input_hidden');
        // $('#sites label').click(function() {
        //     $(this).addClass('defaultValue').siblings().removeClass('defaultValue');
        // });
    },[])

    const tipo_prueba = [ 
        {
            id : 1,
            name: 'Comportamiento'
        },
        {
            id : 2,
            name: 'Personalidad'
        },
        {
            id : 3,
            name: 'Inteligencia'
        }
    ]

    const option_lenght = 5;

    return(
        <section>
            <div className="content-section">
                <div className="main">
                    <Container>

                        <Formik
                            validationSchema={PruebaScheme}
                            initialValues={{
                                'tipo_prueba': -1,
                                'duracion': -1,
                                'nombre': '',
                                'duración': []
                            }} onSubmit={ (values) => {
                                console.log('VALUES  >> ',values)
                            }} 
                        >
                        { ({ errors, touched, validateField, validateForm, setFieldValue   }) =>  (
                            <Form>
                                <h1>Crear pruebas</h1>
                                <div className="asignar_usuario">
                                    <Row>
                                        <Col md="4">
                                            <div className="datos_candidato">
                                                <label>Tipo de prueba</label>
                                                <Field
                                                    as='select'
                                                    id='tipo_prueba'
                                                    name='tipo_prueba'
                                                >
                                                    <option value={-1} >Selecciona el tipo de prueba que deseas añadir</option>
                                                    { tipo_prueba.map( tipo  =>  (<option key={tipo.id} value={tipo.id}>{tipo.name}</option>) ) }
                                                </Field>
                                                {errors.tipo_prueba && touched.tipo_prueba && <div className="error_feedback">{errors.tipo_prueba}</div>}
                                            </div>
                                        </Col>
                                        <Col md="4">
                                            <div className="datos_candidato">
                                                <label>Nombre</label>
                                                <Field name="nombre" id='nombre' type="text" placeholder='Nombre de la prueba'/ >
                                                {errors.nombre && touched.nombre && <div className="error_feedback">{errors.nombre}</div>}
                                            </div>
                                        </Col>
                                        <Col md="4">
                                            <div className="datos_candidato">
                                                <label>Duración de prueba</label>
                                                <Field
                                                    as="select"
                                                    id="duracion"
                                                    name="duracion"
                                                >
                                                    <option>Selecciona el tiempo de duración de la prueba</option>
                                                    {
                                                        [...Array(option_lenght)].map( (_num,index) => {
                                                            if( index % 5 === 0 ){
                                                                return (<option key={index}>{index + ' Minutos'}</option>)
                                                            }else{
                                                                return undefined
                                                            }
                                                        })
                                                    }
                                                </Field>
                                                {errors.duracion && touched.duracion && <div className="error_feedback">{errors.duracion}</div>}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="body_preguntas">
                                    <div className="botones_preguntas">
                                        <label>Tipo de pregunta</label>
                                        <Row>
                                            <Col md="6">
                                                <div className="datos_candidato">
                                                    <select>
                                                        <option >Selecciona el tipo de pregunta que deseas añadir</option>
                                                        <option>Selección múltiple</option>
                                                        <option>Falso y verdadero</option>
                                                        <option>Preguntas directas</option>
                                                        <option>Relaciones de conceptos</option>
                                                    </select>
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <button className="btn_agregar_pregunta">Añadir pregunta</button>  
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className="body_prueba_nueva">
                                    <div className="contenedor_pruebas">
                                        <HeaderPruebas/>
                                        <h1>Preguntas</h1>
                                        <div className="body_preg_indi">
                                            <div className="titulo_pregunta">
                                                <span>Pregunta 1</span><input type="text" name="pregunta" placeholder="Escribe tu pregunta directa aquí" className="input_pregunta"/>
                                            </div>
                                            <div className="respuesta_pregunta">
                                                <input type="text" name="respuesta" placeholder="Escribe tu respuesta aquí"/>
                                            </div>
                                        </div>
                                        <div className="body_preg_indi">
                                            <div className="titulo_pregunta">
                                                <span>Pregunta 2</span><input type="text" name="pregunta" placeholder="Escribe tu pregunta verdadero y falso" className="input_pregunta"/>
                                            </div>
                                            <div className="respuesta_pregunta">
                                                <div className="radio">
                                                        <input onChange={(event)=> console.log(event.target.value)} id="true" type="radio" name="gender" value="true" />
                                                        <label htmlFor="true">Verdadero</label>
                                                        <input id="false" type="radio" name="gender" value="false"/>
                                                        <label htmlFor="false">Falso</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="body_preg_indi">
                                            <div className="titulo_pregunta">
                                                <span>Pregunta 3</span><input type="text" name="pregunta" placeholder="Escribe tu pregunta selección múltiple" className="input_pregunta"/>
                                            </div>
                                            <div className="respuesta_pregunta">
                                                <div className="radio">
                                                        <input id="opcion1" type="radio" name="gender" value="opcion1"/>
                                                        <label htmlFor="opcion1">Opción 1</label>
                                                        <input id="opcion2" type="radio" name="gender" value="opcion2" />
                                                        <label htmlFor="opcion2">Opción 2</label>
                                                        <input id="opcion3" type="radio" name="gender" value="opcion3"/>
                                                        <label htmlFor="opcion3">Opción 3</label>
                                                        <input id="opcion4" type="radio" name="gender" value="opcion4"/>
                                                        <label htmlFor="opcion4">Opción 4</label>
                                                        <input id="opcion5" type="radio" name="gender" value="opcion5"/>
                                                        <label htmlFor="opcion5">Opción 5</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="body_preg_indi">
                                            <div className="titulo_pregunta">                            
                                                <span>Pregunta 4</span><input type="text" name="pregunta" placeholder="Ve la siguiente imagen y encuentra la faltante" className="input_pregunta"/>
                                                <div className="button_subir_foto_pregunta">
                                                        <div className="form-group">
                                                            <label style={{ color : 'white' }} htmlFor="upload" className="btn d-inline-block btn-info">Subir imagen</label>
                                                            <input type="file" name="" id="upload" />
                                                        </div>
                                                </div>
                                                <div className="img_relaciones">
                                                    <img alt="imagen de prueba" src={ImagenPrueba}/>
                                                </div>
                                            </div>
                                            <div className="respuesta_pregunta">
                                                <div className="button_subir_foto_pregunta">
                                                        <div className="form-group">
                                                            <label style={{  color : 'white' }} htmlFor="upload" className="btn d-inline-block btn-info">Añadir respuesta</label>
                                                            <input type="file" name="" id="upload" />
                                                        </div>
                                                </div>
                                                    <div id="sites">
                                                        <input type="radio" name="site" id="option1" value="option1" />A.<label htmlFor="option1"><img src={Respuesta1} alt="Opción 1" /></label>
                                                        <input type="radio" name="site" id="option2" value="option2" />B.<label htmlFor="option2"><img src={Respuesta2} alt="Opción 2" /></label>
                                                        <input type="radio" name="site" id="option3" value="option3" />C.<label htmlFor="option3"><img src={Respuesta3} alt="Opción 3" /></label>
                                                        <input type="radio" name="site" id="option1" value="option1" />D.<label htmlFor="option1"><img src={Respuesta1} alt="Opción 1" /></label>
                                                        <input type="radio" name="site" id="option2" value="option2" />E.<label htmlFor="option2"><img src={Respuesta2} alt="Opción 2" /></label>
                                                        <input type="radio" name="site" id="option3" value="option3" />F.<label htmlFor="option3"><img src={Respuesta3} alt="Opción 3" /></label>
                                                    </div>
                                            </div>
                                            <div className="form-group-guardar-prueba">
                                                <input style={{ color : 'white' }} type="submit" className="btn d-inline-block btn-info" value="Guardar prueba"/>
                                            </div>

                                            <div style={{ paddingTop  : 15 }} className="header_prueba">
                                                <img alt="logo pruebas" src={Logo}/>
                                            </div>
                                            <div className="allrights_footer_prueba">
                                                <p>Esta prueba fue realizada con PruebasPsico</p>
                                                <p>Todos los derechos reservados</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </Form>
                            )}

                    </Formik>

                    </Container>
                </div>
            </div>
        </section>
    )
}

function fromBase64(text) {
  try {
    return atob(text)
  } catch (_e) {
    return text
  }
}


export const Encabezados = [
  {
    name: 'id',
    numeric: true,
    label: 'ID',
    options: {
      display: false
    }
  },
  {
    name: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Nombre',
  },
  {
    name: 'activacion',
    numeric: false,
    disablePadding: true,
    label: 'Fecha Activación',
  },
  {
    name: 'dead',
    numeric: false,
    disablePadding: true,
    label: 'Fecha vencimiento',
  },
  // {
  //   name: 'op',
  //   numeric: false,
  //   disablePadding: true,
  //   label: 'Operación',
  //   options: {
  //     filter: false,
  //     sort: false,
  //   }
  // },
]

export const niveles_educativos = [
  "Primaria",
  "Basico",
  "Diversificado",
  "Tecnico",
  "Pregrado / Licenciatura",
  "Postgrado / Maestria",
  "Doctorado",
  "Diplomado",
  "Certificacion",
  "Cursos"
]

export function Build(pwds) {

  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  //NOTE:
  //js year formt yyyy/mm/dd
  return pwds.map(password => {
    let { pwd, plaza_id, deadpwd, dateToActived } = password
    return {
      id: plaza_id,
      name: fromBase64(pwd),
      activacion: dateToActived ? new Date(dateToActived).toLocaleDateString('es-GT',options) : "",//new Date( dateToActived ), 
      dead: deadpwd ? new Date(deadpwd).toLocaleDateString('es-GT', options) : ""   //new Date( deadpwd ),
    }
  })
}


import React, { useState, useRef } from 'react'
import { Row, Col } from 'react-bootstrap'

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';

import { useNavigate } from 'react-router-dom'
import { inteligencia, comportamiento, personalidad } from '../Utils/TipoTest'

function Pagination () {
    return(
        <div className="pagination_pruebas">
            <div className="botones_pagination">
                <span className="disabled">Ant.</span>
                <span className="active">1</span>
                <span >2</span>
                <span >3</span>
                <span >4</span>
                <span >5</span>
                <span >Sig.</span>
            </div>
        </div>
    )
}
    
function CardPrueba(props){
    let {prueba} = props
    return(
        <Col className="prueba_item" md="3">
            <div className="body_prueba_list">
                <div className="botones_edicion">
                    <div className="contenedor_botones">
                        <Row>
                            <Col md="6">
                                {/*<a href="index" title="Editar"><i className="far fa-edit"></i></a> */}
                                <EditIcon className="grid_btn"/>
                            </Col>
                            <Col md="6">
                                <RemoveRedEyeIcon className="grid_btn"/>
                                {/*  <a href="index" title="Eliminar"><i className="fas fa-times"></i></a> */}
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="modern-Checkbox">
                    <label htmlFor="box-21">{prueba}</label>
                </div>
            </div>
        </Col>
    )
}

export function Pruebas() {
    
    let navigate = useNavigate()

    const all = [...inteligencia, ...comportamiento, ...personalidad]

    let [pruebas, setPruebas] = useState(all)

    let main =  useRef(null);
    
    return (
        <section>
            <div className="content-section">
                <div className="main main_pruebas_list">
                    <h1>Listado de pruebas psicométricas</h1>
                    {/* <Row style={{ display : 'flex', alignItems: 'baseline' }}> */}
                    <Row>
                        <Col sm='6' md='8' className="busqueda_prueba">
                            <Row>
                                <Col style={{  padding : 0, margin : 0 }} xs='6' sm='6' md="8">
                                    <input 
                                        onChange={(event) => {
                                            let nombre = event.target.value
                                            if(nombre){
                                                let temp = pruebas.filter( prueba  => {
                                                    let isset = prueba.toUpperCase().includes( nombre.toUpperCase())
                                                    return isset
                                                })
                                                setPruebas(temp)
                                            }else{
                                                setPruebas(all)
                                            }
                                        }} 
                                        type="text" 
                                        name="nombreprueba" 
                                        placeholder="Escribe el nombre de la prueba que deseas consultar"/>
                                </Col>
                                <Col style={{ paddinRight : 0 }} xs='6' sm='6' md="4">
                                    <div className="buscar_prueba">
                                        <button className="btn_buscar_prueba">BUSCAR</button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm='12' md="4">
                            <div className="crear_prueba">
                                <button 
                                    onClick={ () =>  navigate('/dashboard/pruebas/crear') } 
                                    className="btn_buscar_prueba">CREAR PRUEBA
                                </button>
                            </div>
                        </Col>
                    </Row>

                    <div className="tabs_pruebas">
                        <Row >
                            <Col md="3">
                                <div 
                                    ref={main}
                                    className="test_tab active" 
                                    id="todaspruebas" 
                                    onClick={() => setPruebas(all)}
                                >
                                    <p>TODAS LAS PRUEBAS</p>
                                </div>
                            </Col>
                            <Col md="3">
                                <div 
                                    className="test_tab" 
                                    id="comportamiento" 
                                    onClick={() => setPruebas(comportamiento)}
                                >
                                    <p>COMPORTAMIENTO</p>
                                </div>
                            </Col>
                            <Col md="3">
                                <div 
                                    className="test_tab" 
                                    id="personalidad" 
                                    onClick={() => setPruebas(personalidad)}
                                >
                                    <p>PERSONALIDAD</p>
                                </div>
                            </Col>
                            <Col md="3">
                                <div 
                                    className="test_tab" 
                                    id="inteligencia" 
                                    onClick={() => setPruebas(inteligencia)}>
                                    <p>INTELIGENCIA</p>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="pruebas_grid">
                        <div className="todas_las_pruebas_body" id="todas_las_pruebas_body">
                            <Row>
                                {
                                    pruebas.length ?
                                    pruebas.map( 
                                        (prueba, index) => <CardPrueba key={index} prueba={prueba}/>  
                                    )
                                    : undefined
                                }
                            </Row>
                            <Pagination/>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

import * as React from 'react';

import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';

export function Header() {

  return (
    <Card sx={{ display: 'flex' }}>
      <CardMedia
        component="img"
        sx={{ width: '100%', height: 200 }}
        image={"https://static6.depositphotos.com/1000674/642/i/950/depositphotos_6427644-stock-photo-taking-a-test.jpg"}
        alt="Live from space album cover"
      />
    </Card>
  );
}

import React from 'react'
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';

import {
  Box, Button, Stack, TextField, Divider, Typography,
} from '@mui/material';

import { AddCircle } from '@mui/icons-material/';
import { MailTable } from './MailTable';
import toast, { Toaster } from 'react-hot-toast';

export function Candidato(props) {

  const CandidatoSchema = Yup.object().shape({
    nombres:
      Yup.string()
        .min(2, 'Demasiado corto!')
        .max(50, 'Demasiado largo!'),
    apellidos:
      Yup.string()
        .min(2, 'Demasiado corto!').max(50, 'Demasiado Largo!'),
    mail:
      Yup.string()
        .email("Correo no valido")
  });

  let { handleNext, handleBack, password, setPassword } = props

  const formik = useFormik({
    initialValues: {
      nombres: "",
      apellidos: "",
      sendmail: true,
      mail: "",
      nombrepuesto: '',// password.nombrepuesto ? password.nombrepuesto : password.puesto[0].nombrepuesto,
      nombreEmpresa: 'test'//password.nombreEmpresa ? password.nombreEmpresa : auth.getProfile().nombre,
      // nombreEmpresa: password.nombreEmpresa ? password.nombreEmpresa : auth.getProfile().nombre,
    },
    onSubmit: () => {
      if(password.candidatos.length <= 0){
        toast.error("Seleciona almenos un destino")
        return;
      }
      //setPassword({ ...password, ...fields })
      handleNext()
    },
    validationSchema: CandidatoSchema,
  });

  const { errors, touched, handleSubmit, getFieldProps, values, resetForm,isValid } = formik;

  const addToList = () => {

    if(!values.nombres || !values.apellidos || !values.mail){
      return toast.error("Completa la informacion del candidato")
    }

    if(!isValid) {
      return toast.error("Completa la informacion del candidato")
    }

    let user = {
      'nombres': values.nombres,
      'apellidos': values.apellidos,
      'mail': values.mail,
    }
    let new_users = [...password.candidatos, user ]

    setPassword({...password, candidatos: new_users })
    resetForm();
    console.log(values);
  }

  const removeFromList = (umail) => {
    let without = password.candidatos.filter( user => user.mail !== umail )
    setPassword({...password, candidatos: without })
  }

  return (
    <FormikProvider style={{ padding: 25 }} value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

        <Typography style={{ paddingBottom :15 }}>Ingresa uno o mas destinos</Typography>
        <Divider/>

        <Stack spacing={3}>
          <Stack style={{paddingTop: 15}} direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              label="Nombre"
              fullWidth
              {...getFieldProps('nombres')}
              error={Boolean(touched.nombres && errors.nombres)}
              helperText={touched.nombres && errors.nombres}
            />

            <TextField
              label="Apellidos"
              fullWidth
              {...getFieldProps('apellidos')}
              error={Boolean(touched.apellidos && errors.apellidos)}
              helperText={touched.apellidos && errors.apellidos}
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              type="email"
              label="Correo Electronico"
              {...getFieldProps('mail')}
              error={Boolean(touched.mail && errors.mail)}
              helperText={touched.mail && errors.mail}
            />

            <Button onClick={addToList}>
              <AddCircle style={{color:'var(--main)'}}/>
            </Button>

          </Stack>

          <MailTable 
            remove={removeFromList} 
            users={password.candidatos}
            />

          <Box sx={{ mb: 2 }}>
            <div style={{ paddingTop: 15 }}>
              <Button
                type="submit"
                className="registerBtn"
                variant="contained"
                sx={{ mt: 1, mr: 1 }}
              >
                {'Siguiente'}
              </Button>
              <Button
                disabled={false}
                onClick={handleBack}
                sx={{ mt: 1, mr: 1 }}
              >
                Regresar
              </Button>
            </div>
          </Box>

        </Stack>
        <Toaster position="top-right" />
      </Form>
    </FormikProvider>
  );
}

import * as React from 'react';
import { default as CustomToolbar } from '../Components/Password/CustomToolbar';
import { Paper, Box, ThemeProvider } from '@mui/material';

import MUIDataTable from "mui-datatables";
import { Encabezados, Build } from '../Components/Password/Rows';
import { TableStyle, TextLabels } from '../Components/Password/TableStyle'

import { useQuery } from 'react-query';
import { Service } from '../Utils/HTTP.js'
import { useSelector } from 'react-redux';
import {  ModalEdit } from '../Components/Password/Operation'

export function Contrasv2() {

  const auth = useSelector((state) => state.token)
  const [visible,setVisible] = React.useState(false)
  const [password,setPassword] = React.useState(null)

  const getAllPwd = async () => {
    let rest = new Service('/contrasenia/getallbyidUsr');
    return await rest.getQuery(auth.token)
  }

  const hideShowPWD = (status) => {
    setVisible(status)
    if(status === false){
      setPassword(null);
    }
  }

  const { data, status } = useQuery('passwords', getAllPwd);

  const options = {
    filterType: 'checkbox',
    customToolbar: () => {
      return (
        <CustomToolbar />
      );
    },
    textLabels: TextLabels,
    onRowClick: (password) => {
      let [plaza, pwd] = password;
      setPassword({pwd,plz:plaza});
      setVisible(true);
    },
    elevation:9,
    rowsPerPage: 5,
    count: 5,
    download:false
  };

  return (
    <div className="content-section">
      <div className="main">
        <Box sx={{ width: '100%' }}>
          <Paper elevation={0} sx={{ mb: 2, padding: 2, height: '100%', minHeight: '95vh', boxShadow: 'none !important' }}>
            <ThemeProvider theme={TableStyle}>
              <MUIDataTable
                sx={{ '& MuiPaper': { elevation: 0, boxShadow: 'none', color: "red" } }}
                title={"Contraseñas"}
                data={Build(status === 'success' ? data.data : [])}
                columns={Encabezados}
                options={options}
              />
            </ThemeProvider>
          </Paper>
        </Box>
        {
          password ?
            (
              <ModalEdit
                password={password}
                open={visible}
                handleOpen={hideShowPWD}
              />
            ): null
        }
      </div>
    </div>
  );

}

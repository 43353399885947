import React, { memo } from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import toast from 'react-hot-toast';

import { AdapterDateFns as DateFnsUtils } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import {
  Button, Stack, TextField, MenuItem, FormControl, InputLabel, Select,
  Backdrop, CircularProgress, Box, Divider,
  Tabs, Tab, FormGroup, Checkbox, FormControlLabel,
  Dialog, DialogContent, DialogTitle, DialogActions,
  DialogContentText,
} from '@mui/material';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import { Service } from '../../Utils/HTTP';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { TabPanel } from './TabPanel'
import { useSelector } from 'react-redux';

const filter = createFilterOptions();

async function getPuestoSuperior(puesto, auth) {
  if (puesto.length < 2) return []
  let rest = new Service(`/plaza/keypuestosup?keyword=${puesto}`)
  let result = await rest.get(auth.token)
  if (result?.data?.length > 0) {
    result = result.data.map((item) => ({ 'title': item.nombre, id: item.id }))
    return result;
  }
  return [];
}

async function savePuestoSuperior(input, auth) {
  let rest = new Service("/plaza/puestosuperior")
  let body = {
    "active": 1,
    "nombre": input.title,
    "decription": input.id,
    "modifyday": "2023-02-12T23:55:26.007",
    "createday": "2023-02-12T23:55:26.007",
    "id": null,
    "descripction": input.id,
    "modify_day": "2023-02-12T23:55:26.007",
  }
  let result = await rest.post(body, auth.token);
  let { id, nombre } = result;
  return { id, nombre }
}


function Manual(props) {

  const auth = useSelector((state) => state.token)

  const getCategories = async () => {
    let rest = new Service("/categoria/getAll")
    return await rest.getQuery(auth.token)
  }

  const getTest = async () => {
    let rest = new Service("/tests/getAll")
    return await rest.getQuery(auth.token)
  }


  const { data } = useQuery('categories', getCategories);
  const { data: testsCatalog } = useQuery('tests', getTest);
  const queryClient = useQueryClient();

  const NewPlazaSchema = Yup.object().shape({
    nombrepuesto: Yup.string().required('El nombre es requerido').min(5, "El nombre del  puesto debe ser mayor a 5 caracteres").max(100),
    puestosuperior: Yup.number().required("El puesto superior es requerido"),
    aredepto: Yup.number().required('Escoge alguna área'),
    fecha: Yup.date("Ingresa una fecha válida"),
    notas: Yup.string("Ingresa una nota válida").min(5, "Ingresa una nota válida").max(150),
    tests: Yup.array()
  })

  const [departamento, setDepartamento] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [date, setDate] = React.useState(new Date());
  const [tab, setTab] = React.useState(0);
  const [checklist, setChecklist] = React.useState([]);
  const [openDialog, toggleOpenDialog] = React.useState(false);
  const [openSugg, setOpenSugg] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [dialogValue, setDialogValueHook] = React.useState({
    title: '',
    id: '',
  });

  let setDialogValue = (value) => {
    // console.log('setDialogValue',value)
    // if (value?.id !== undefined && isNaN(value?.id) === false) {
    //   console.log('first if')
    setValues({ ...values, puestosuperior: value?.id })
    // }
    setDialogValueHook(value)
    // console.log(values)
  }

  const loading = openSugg && options.length === 0;

  React.useEffect(() => {

    let active = true;
    if (!loading) {
      return undefined;
    }

    (async () => {
      let puestos = await getPuestoSuperior(dialogValue.title, auth)
      if (active) {
        setOptions(puestos);
      }
    })();

    return () => {
      active = false;
    };

  }, [loading, dialogValue, auth]);

  const handleClose = () => false

  const changeDepartamento = (event) => {
    setDepartamento(event.target.value);
  };

  const handleCloseDialog = () => {
    toggleOpenDialog(false);
  };


  const handleSubmitDialog = async (event) => {
    event.preventDefault();
    let { id, nombre } = await savePuestoSuperior(dialogValue, auth)
    if (id) {
      let to_set = {
        title: nombre,
        id: id,
      }
      if (to_set.id) {
        setDialogValue(to_set);
      }
    }
    handleCloseDialog();
  };

  const AutoCompleteChange = (event, newValue) => {
    // FIX: este set es el que realmente agrega al estado
    setValues({ ...values, puestosuperior: newValue?.id })
    if (typeof newValue === 'string') {
      setTimeout(() => {
        toggleOpenDialog(true);
        setDialogValue({
          title: newValue,
          id: '',
        });
      });
    } else if (newValue && newValue.inputValue) {
      toggleOpenDialog(true);
      setDialogValue({
        title: newValue.inputValue,
        id: '',
      });
    } else {
      setDialogValue(newValue);
    }
  }

  const agregarPuesto = async (puesto) => {
    let rest = new Service('/plaza/save');
    return await rest.postQuery(puesto, auth.token);
  }

  const puestoMutation = useMutation(agregarPuesto)
  let { visible, onClose } = props

  const formik = useFormik({
    initialValues: {
      nombrepuesto: "",
      puestosuperior: null,
      aredepto: 1,
      fecha: date,
      notas: "",
      tests: []
    },
    onSubmit: (fields, { resetForm }) => {

      if (fields.tests.length === 0) {
        toast.error("Recuerda que seleccionar al menos un test")
        setTab(1)
        return
      }
      setOpen(true)
      fields['fecha'] = new Date(fields.fecha).toISOString();
      fields['areadeptoplz_id'] = 1;
      fields['id'] = -1;
      puestoMutation.mutate(fields, {
        onSuccess: () => {
          setOpen(false)
          resetForm();
          onClose();
          queryClient.invalidateQueries('puestos')
          toast.success("Puesto Agregado")
        },
        onError: () => {
          setOpen(false)
          toast.error("Ups!! Ocurrio un error, inténtalo más tarde")
        }
      })
    },
    validationSchema: NewPlazaSchema,
  });

  const changeTab = (_event, newValue) => setTab(newValue);

  const { errors, touched, handleSubmit, getFieldProps, values, setValues } = formik;

  const addPrueba = (check, id) => {
    let { tests } = values
    let temp;
    if (check) {
      temp = [...tests, { id }]
    } else {
      temp = tests.filter(test => test.id !== id);
    }
    setChecklist(temp.map(test => test.id))
    setValues({ ...values, tests: temp })
  };

  return (

    <Dialog
      open={visible}
      fullWidth={true}
      maxWidth="md"
      aria-labelledby="contained-modal-title-vcenter"
      onClose={onClose}>

      <DialogTitle className="modal-title" style={{ color: '#252525' }}>
        <button onClick={onClose} type="button" className="close" data-dismiss="modal">&times;</button>
        Agregar Puesto
      </DialogTitle>

      <DialogContent className="modal-body">

        <Tabs value={tab} onChange={changeTab} aria-label="basic tabs example">
          <Tab label="Información" />
          <Tab label="Pruebas" />
        </Tabs>

        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <form onSubmit={handleSubmitDialog}>
            <DialogTitle>Agrega un nuevo Puesto</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Agrega la descripcion del puesto
              </DialogContentText>

              <TextField
                fullWidth
                autoFocus
                margin="dense"
                id="name"
                value={dialogValue?.title}
                onChange={(event) =>
                  setDialogValue({
                    ...dialogValue,
                    title: event.target.value,
                  })
                }
                label="Puesto"
                type="text"
                variant="standard"
              />

              {/*
                <TextField
                  margin="dense"
                  id="name"
                  value={dialogValue?.id}
                  onChange={(event) =>
                      setDialogValue({
                        ...dialogValue,
                        id: event.target.value,
                  })
                  }
                  label="Descripción"
                  type="text"
                  variant="standard"
              />
              */}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}>Cancelar</Button>
              <Button type="submit">Agregar</Button>
            </DialogActions>
          </form>
        </Dialog>



        <FormikProvider style={{ paddingTop: 25 }} value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

            <TabPanel value={tab} index={1}>
              <Stack spacing={1}>
                <Box style={{ paddingTop: 5, paddingLeft: 15 }}>
                  <Divider />
                  <h4 style={{ paddingTop: 10, paddingBottom: 10 }}>Selecciona los test a realizar</h4>
                  <Divider />
                  <FormGroup>
                    {
                      testsCatalog ?
                        testsCatalog.data.map(test => (
                          <FormControlLabel
                            key={test.id}
                            control={
                              <Checkbox
                                checked={checklist.includes((test.id))}
                                onChange={(event) => addPrueba(event.target.checked, test.id)}
                              />
                            }
                            label={test.nombre}
                          />
                        )) : null
                    }
                  </FormGroup>
                </Box>
              </Stack>
            </TabPanel>

            <TabPanel value={tab} index={0}>

              <Stack spacing={3}>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={4}>
                  <TextField
                    label="Nombre"
                    fullWidth
                    {...getFieldProps('nombrepuesto')}
                    helperText={touched.nombrepuesto && errors.nombrepuesto}
                    error={Boolean(touched.nombrepuesto && errors.nombrepuesto)}
                  />

                  <FormControl fullWidth>

                    <Autocomplete
                      fullWidth
                      value={dialogValue}
                      onChange={AutoCompleteChange}
                      open={openSugg}
                      onOpen={() => {
                        setOpenSugg(true);
                      }}
                      onClose={() => {
                        setOpenSugg(false);
                      }}
                      isOptionEqualToValue={(option, value) => option.title === value.title}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        if (params.inputValue !== '') {
                          filtered.push({
                            inputValue: params.inputValue,
                            title: `Agregar "${params.inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      id="puesto_superior_autocomplete"
                      options={options}
                      loading={loading}
                      getOptionLabel={(option) => {
                        if (typeof option === 'string') {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option.title;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      renderOption={(props, option) => <li key={option.id} {...props}>{option.title}</li>}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          {...getFieldProps('puestosuperior')}
                          error={Boolean(touched.puestosuperior && errors.puestosuperior)}
                          label="Puesto Superior"
                          InputProps={{
                            ...params.InputProps,
                            onChange: (event) => {
                              // let title = event.target.value;
                              setOptions([]);
                              setDialogValue({
                                title: event.target.value,
                                id: '',
                              });
                            },
                            endAdornment: (
                              <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}

                    />

                  </FormControl>

                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={4}>

                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Departamento</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      value={departamento}
                      label="Departamento"
                      onChange={changeDepartamento}
                      {...getFieldProps('aredepto')}
                      error={Boolean(touched.aredepto && errors.aredepto)} >
                      {
                        data ?
                          data.data.map(cate => {
                            return (
                              <MenuItem key={cate.id} value={cate.id}>{cate.nombre}</MenuItem>
                            )
                          })
                          : <MenuItem>Null</MenuItem>
                      }
                    </Select>
                  </FormControl>


                  <LocalizationProvider
                    dateAdapter={DateFnsUtils}>
                    <DesktopDatePicker
                      label="Fecha Creación"
                      fullWidth
                      inputFormat="dd/MM/yyyy"
                      {...getFieldProps('fecha')}
                      value={date}
                      onChange={setDate}
                      renderInput={(params) =>
                        <TextField
                          disabled={true}
                          label="Fecha Creación"
                          fullWidth
                          {...params}
                        />}
                    />
                  </LocalizationProvider>

                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={4}>
                  <TextField
                    id="filled-multiline-static"
                    multiline
                    rows={4}
                    variant="filled"
                    label="Notas"
                    fullWidth
                    type="text"
                    {...getFieldProps('notas')}
                    error={Boolean(touched.notas && errors.notas)}
                    helperText={touched.notas && errors.notas}
                  />
                </Stack>
              </Stack>

            </TabPanel>


            <DialogActions>
              <Button
                type="submit"
                className="registerBtn"
                variant="contained"
                sx={{ mt: 1, mr: 1 }} >
                {'Guardar'}
              </Button>
            </DialogActions>

          </Form>
        </FormikProvider>
      </DialogContent>

      <Backdrop
        sx={{ color: '#fd4b4b', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose} >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  )
}
export default memo(Manual);

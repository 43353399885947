// import { useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { TableStyle, Encabezados, BuildColumns } from './Config'
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { Service } from '../../Utils/HTTP'
import MUIDataTable from "mui-datatables";

export const TestDataTable = (props) => {
  
  let { setId } = props; 
  const auth = useSelector((state) => state.token)

  const options = {
    filterType: 'checkbox',
    customToolbar: () => { },
    // textLabels: TextLabels,
    onRowClick: (test) => {
      //TODO: render exam
      // console.log(test)
      setId(test[0])
    },
    elevation: 9,
    download : false,
    // count: 2
  };

  const getAllPwd = async () => {
    let rest = new Service('/contrasenia/getallbyidUsr');
    return await rest.getQuery(auth.token)
  }

  const { data, status } = useQuery('homepwd', getAllPwd);

  return (
    <ThemeProvider theme={TableStyle}>
      <MUIDataTable
        sx={{ '& MuiPaper': { elevation: 0, boxShadow: 'none', color: "red" } }}
        title={"Expedientes"}
        data={BuildColumns( status === 'success' ? data.data : [], setId )}
        columns={Encabezados}
        options={options}
      />
    </ThemeProvider>
  )
}

import React, { useEffect , useState} from 'react'
import {Col, Row } from 'react-bootstrap'
import { inteligencia, comportamiento, personalidad } from '../Utils/TipoTest'
import AyudaAsignarModal, { ShowComportamiento, ShowPersonalidad, ShowInteligencia } from '../Components/Modal/AyudaAsignar'

// function* idMaker() {
//     var index = 1;
//     while(true)
//         yield index++;
// }
// //
// var ID = idMaker(); 

function CardPrueba(props){


    return(
        <Col  md="4">
            <div className="body_prueba">
                <div className="modern-Checkbox">
                </div>
            </div>
        </Col>
    )
}

export function PruebaAsignar() {


    useEffect(() => {

        // $(".list-unstyled").find('li').removeClass("active");
        // $("#pruebas").addClass("active");
        // $("#aplicar").addClass("active");

        ShowComportamiento();

    },[])

    let [ modal, setModal  ] = useState(false)

    return(
        <section>
            <div className="content-section">
                <div className="main">
                    <h1>Asignación de pruebas</h1>
                    <Row>
                        <Col style={{ marginBottom : 15 }}>
                            <p>Selecciona el código del candidato y posteriormente las pruebas que deberá ejecutar.</p>
                        </Col>
                    </Row>
                    <div className="asignar_usuario">
                        <Row>
                            <Col md="3">
                                <div className="datos_candidato">
                                    <label>Seleccionar código</label>
                                    <select>
                                        <option>001</option>
                                        <option>002</option>
                                        <option>003</option>
                                        <option>004</option>
                                        <option>005</option>
                                        <option>006</option>
                                        <option>007</option>
                                        <option>008</option>
                                    </select>
                                </div>
                            </Col>
                            <Col md="3">
                                <div className="datos_candidato">
                                    <label>Nombre</label>
                                    <input type="text" name="nombre" placeholder="Nombre candidato" readOnly/>
                                </div>
                            </Col>
                            <Col md="3">
                                <div className="datos_candidato">
                                    <label>Edad</label>
                                    <input type="number" name="text" placeholder="28" readOnly/>
                                </div>
                            </Col>
                            <Col md="3">
                                <div className="datos_candidato">
                                    <label>Género</label>
                                    <input type="text" name="genero" placeholder="Hombre" readOnly/>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="tabs_pruebas">
                        <Row className="row">
                            <Col md="4">
                                <div className="test_tab active" id="comportamiento" onClick={ShowComportamiento}>
                                    <p>COMPORTAMIENTO</p>
                                </div>
                            </Col>
                            <Col md="4">
                                <div className="test_tab" id="personalidad" onClick={ShowPersonalidad}>
                                    <p>PERSONALIDAD</p>
                                </div>
                            </Col>
                            <Col md="4">
                                <div className="test_tab" id="inteligencia" onClick={ShowInteligencia}>
                                    <p>INTELIGENCIA</p>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="pruebas_grid">

                        <div className="comportamiento_body" id="comportamiento_body">
                            <Row>
                                {
                                    comportamiento.map( (test, index) => {
                                        return (
                                            <CardPrueba key={index} name={test} id={index}/>
                                        )
                                    })
                                }
                            </Row>
                        </div>



                        <div className="personalidad_body" id="personalidad_body">
                            <Row>
                                {
                                    personalidad.map( (test, index) => {
                                        return (
                                            <CardPrueba key={index} name={test} id={index}/>
                                        )
                                    })
                                }
                            </Row>
                        </div>



                        <div className="inteligencia_body" id="inteligencia_body">
                            <Row>
                                {
                                    inteligencia.map( (test, index) => {
                                        return (
                                            <CardPrueba key={index} name={test} id={index}/>
                                        )
                                    })
                                }
                            </Row>
                        </div>


                        <div className="botones_interactivos_asignar">
                            <Row>
                                <Col md="12">
                                    <span onClick={() => setModal(true)}>Ayuda</span>
                                    <button>Asignar</button>
                                </Col>
                            </Row>
                        </div>

                    </div>
                </div>
            </div>
            <AyudaAsignarModal visible={modal} onClose={() => setModal(false)} />
        </section>
    )
}

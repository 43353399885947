import React from "react";
import '../../Css/loading.css'

import { Box, Paper } from '@mui/material'
  
const types = [
  "dot-pulse", "dot-elastic", "dot-flashing", "dot-collision", "dot-revolution", "dot-carousel",
  "dot-fire", "dot-spin", "dot-falling", "dot-stretching"
];


export function Loading() {


  return (
    <div className="content-section">
      <Paper>
        <div className="main">
          <Box className="loadingGeneric">
            <div className="loading_or_content">
              <div className="snippet" data-title=".dot-pulse">
                <div className="stage">
                  <div className={types[0]}></div>
                </div>
              </div>
            </div>
          </Box>
        </div>
      </Paper>
    </div>

  )
}

export function Simple() {
  return (
    <div className="snippet" data-title=".dot-pulse">
      <div className="stage">
        <div className={types[0]}></div>
      </div>
    </div>

  )
}

export function Centred() {
  return (
    <div className="loadingBase" data-title=".dot-pulse">
      <div className="lodingSpinner">
        <div className={types[0]}></div>
      </div>
    </div>

  )
}




import { combineReducers } from '@reduxjs/toolkit'
import tokenReducer from '../Slices/tokenSlice.js';
import userReducer from '../Slices/userSlice.js';
import cleaverSlice from '../Slices/cleaverSlice.js';
import recluterSlice from '../Slices/recluterSlice.js';

const rootReducer = combineReducers({
  token : tokenReducer,
  user: userReducer,
  cleaver : cleaverSlice,
  recluter : recluterSlice
});

export default rootReducer;
